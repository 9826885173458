import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_CANTIDAD_RESULTADOS, FILTER_ADD_METODO_ENTREGA, FILTER_CODIGO_VENDEDOR, FILTER_COMUNA_ENTREGA, FILTER_DXC, FILTER_ESTATUS, FILTER_FACTURA, FILTER_FECHA_FINAL, FILTER_FECHA_INICIO, FILTER_NP, FILTER_NV, FILTER_PLANIFICACION_FINAL, FILTER_PLANIFICACION_INICIO, FILTER_PUNTO_ENTREGA, FILTER_RUT_CLIENTE, FILTER_TIPO_DESTINO } from '../../redux/redux-types'
import { cantidadResultadosFilter, metodoEntregaFilter, clearFilterNotasVentas, codigoVendedorFilter, comunaEntregaFilter, destinoFilter, dxcFilter, estatusFilter, facturaFilter, fechaDesdeFilter, fechaHastaFilter, npFilter, nvFilter, planificacionDesdeFilter, planificacionHastaFilter, puntoEntregaFilter, resultadosNotasVentas, rutClienteFilter, resultadosPlanificacion, filtrosPersistentesNotasVentas } from '../../redux/actions/notasVentas'


// Components
import FormInput from '../FormInput'
import FiltroAplicado from './FiltroAplicado'
import SelectFilter from '../SelectFilter'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { lastPosition } from '../../helpers/lastPosition'
import { idExtractor } from '../../helpers/idExtractor'
import DownloadFile from '../Download/DownloadFile'
import { persistentFilters } from '../../helpers/pesistentFilters'
// import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'


const CONSTANT = {
  queryUrlGetAll: getAll.notas_ventas + '/listar',
  queryExportar: getAll.notas_ventas + '/exportar',
  getIsPlanificacion: getAll.notas_ventas + '/planificacion/listar',
  redirectUrl: '/notas-ventas',
  title: 'Búsqueda de notas de venta',
  titlePlanificacion: 'Búsqueda de planificación',
  reduxClearFilters: clearFilterNotasVentas,
  reduxSetResults: resultadosNotasVentas,
  reduxSetResultsPlanificacion: resultadosPlanificacion,

  fetchTiposDestinos: getAll.tipos_destino,
  fetchVendedores: getAll.erp_vendedores + '/obtener',
  fetchErpComunas: getAll.erp_comunas,
  fetchMetodosEntrega: getAll.metodos_entrega,

  selectFilterInitialState: {
    vendedores: [],
    estatus: [{ name: 'Abierta' }, { name: 'Cerrada' }, { name: 'Cerrada manualmente' }, { name: 'Nula' }],
    comunas: [],
    destinos: [],
    metodoEntrega: []
  },

  queryUrl: (results) => CONSTANT.queryUrlGetAll + `?limit=${results}`
}

export const dataFormatter = (obj, isPlanificacion = false) => {
  let data = {}

  Object.entries(obj).forEach(([k, v]) => {
    if (isPlanificacion &&
      ['dxc', 'np', 'factura', 'estatus', 'comuna_entrega_cod',
        'fecha_hasta', 'fecha_desde', 'factura', 'rut_cliente', 'punto_entrega'].includes(k)) return

    if (['dxc', 'np', 'factura', 'limite'].includes(k) && v.length) {
      return data = {
        ...data,
        [k]: Number(v)
      }
    }

    if (['metodos_entregas',].includes(k) && v.length) {
      return data = {
        ...data,
        [k]: v.map(str => idExtractor(str))
      }
    }

    if (['destino', 'codigo_vendedor', 'estatus', 'comuna_entrega_cod',].includes(k) && v.length) {
      return data = {
        ...data,
        [k]: v.split('-')[0]
      }
    }

    if (['fecha_hasta', 'fecha_desde', 'factura', 'rut_cliente', 'nv', 'punto_entrega', 'planificacion_desde', 'planificacion_hasta'].includes(k) && v.length) {
      return data = {
        ...data,
        [k]: v
      }
    }
  })
  return data
}


const fetchSelectFiltersData = async () => {
  return await Promise.all([
    axios(CONSTANT.fetchMetodosEntrega, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => ({ metodoEntrega: data }))
      .catch(err => {
        console.error(err)
        return { metodoEntrega: [{ codigo_vendedor: 'Error', vendedor: 'Error' }] }
      }),

    axios(CONSTANT.fetchVendedores, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => ({ vendedores: data?.data }))
      .catch(err => {
        console.error(err)
        return { vendedores: [{ codigo_vendedor: 'Error', vendedor: 'Error' }] }
      }),

    axios(CONSTANT.fetchTiposDestinos, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => ({ destinos: data }))
      .catch(err => {
        console.error(err)
        return { destinos: [{ id: 'Error', nombre: 'Error' }] }
      }),

    axios(CONSTANT.fetchErpComunas, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
      .then(({ data }) => ({ comunas: data?.comunas }))
      .catch(err => {
        console.error(err)
        return { comunas: [{ nombre: 'Error', cod_maxi: 'Error' }] }
      })
  ])
    .then(arrRes => {
      let res = {}
      arrRes.forEach(d => Object.entries(d).forEach(([k, v]) => res = { ...res, [k]: v }))
      return res
    })
}


const NotasVentasFiltros = ({ isPlanificacion = false }) => {
  const { filtros } = useSelector(state => state.notasVentas)
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const {
    nv,
    np,
    factura,
    rut_cliente,
    destino,
    codigo_vendedor,
    metodos_entregas,
    estatus,
    punto_entrega,
    comuna_entrega_cod,
    fecha_desde,
    planificacion_desde,
    planificacion_hasta,
    limite,
    fecha_hasta,
    dxc,
  } = filtros
  const [iframeData, setIframeData] = useState(undefined)

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  // useEffect(() => {
  //   if (isPlanificacion) return

  //   const filtrosPersistentesObj = persistentFilters(window.location, filtros).obj
  //   let url = (planificacion) => planificacion ? CONSTANT.getIsPlanificacion : CONSTANT.queryUrlGetAll
  //   let config = {
  //     headers: {
  //       'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
  //     },
  //     method: 'POST',
  //     data: { fecha_desde: todayFormat() }
  //   }

  //   !filtrosPersistentesObj && dispatch(fechaDesdeFilter(todayFormat()))

  //   if (filtrosPersistentesObj) {
  //     dispatch(filtrosPersistentesNotasVentas(filtrosPersistentesObj))
  //     config = { ...config, data: dataFormatter(filtrosPersistentesObj) }
  //   }

  //   axios(`${url(isPlanificacion)}?limit=20`, config)
  //     .then(({ data }) => (isPlanificacion)
  //       ? dispatch(CONSTANT.reduxSetResultsPlanificacion(data?.data ?? []))
  //       : dispatch(CONSTANT.reduxSetResults(data?.data ?? []))
  //     )
  //     .catch(err => {
  //       console.error(err)
  //       dispatch(CONSTANT.reduxSetResults({ nv: [] }))
  //     })
  // }, [dispatch, isPlanificacion])


  // EFECTO QUE SETEA LOS VALORES DE LOS SELECTFILTERS Y FECHA DESDE
  useEffect(() => {
    fetchSelectFiltersData().then(data => setSelectFilter((selectFilter) => ({ ...selectFilter, ...data })))
  }, [dispatch])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetch = (reset = false, target) => {
    let url = (planificacion, target = null) => {
      return planificacion
        ? CONSTANT.getIsPlanificacion
        : (target === 'exportar')
          ? CONSTANT.queryExportar
          : CONSTANT.queryUrlGetAll
    }

    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data: {}
    }

    if (isPlanificacion && (!planificacion_desde && !planificacion_hasta)) return dispatch(fireToaster({ title: 'Por favor, ingrese una fecha de planificación', icon: 'warning' }))

    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      // dispatch(CONSTANT.reduxSetResults([]))
      dispatch(CONSTANT.reduxSetResultsPlanificacion(null))

      return
      // axios(url(isPlanificacion), config)
      //   .then(({ data }) => (isPlanificacion)
      //     ? dispatch(CONSTANT.reduxSetResultsPlanificacion(data?.data ?? []))
      //     : dispatch(CONSTANT.reduxSetResults(data?.data ?? []))
      //   )
      //   .catch(err => {
      //     console.error(err)
      //     dispatch(CONSTANT.reduxSetResults([]))
      //   })
      //   .finally(() => {
      //     setIsConsulting(false)
      //   })
    }

    config = { ...config, data: dataFormatter(filtros, isPlanificacion) }

    dispatch(fireToaster({ title: 'Realizando consulta', icon: 'info', text: 'Buscando...' }))

    setIsConsulting(true)

    axios(url(isPlanificacion, target), config)
      .then(({ data }) => {
        if (target === 'exportar') { console.log(data); setIframeData(data) }

        (isPlanificacion)
          ? dispatch(CONSTANT.reduxSetResultsPlanificacion(data?.data ?? []))
          : dispatch(CONSTANT.reduxSetResults(data?.data ?? []))

        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        window.history.pushState({}, '', persistentFilters(window.location, filtros).urlWithFilters)
      })
      .catch(err => {
        console.error(err);
        dispatch(fireToaster({ title: `Operación fallida: Error ${err?.response?.status}`, icon: 'error', text: 'No se pudo hacer la consulta' }))
        dispatch(CONSTANT.reduxSetResults([]))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_COMUNA_ENTREGA:
        dispatch(comunaEntregaFilter(value))
        break;

      case FILTER_ESTATUS:
        dispatch(estatusFilter(value))
        break;

      case FILTER_CODIGO_VENDEDOR:
        dispatch(codigoVendedorFilter(value))
        break;

      case FILTER_DXC:
        dispatch(dxcFilter(value))
        break;

      case FILTER_PUNTO_ENTREGA:
        dispatch(puntoEntregaFilter(value))
        break;

      case FILTER_TIPO_DESTINO:
        dispatch(destinoFilter(value))
        break;

      case FILTER_RUT_CLIENTE:
        dispatch(rutClienteFilter(value))
        break;

      case FILTER_FACTURA:
        dispatch(facturaFilter(value))
        break;

      case FILTER_NP:
        dispatch(npFilter(value))
        break;

      case FILTER_NV:
        dispatch(nvFilter(value))
        break;

      case FILTER_FECHA_INICIO:
        dispatch(fechaDesdeFilter(value))
        break;

      case FILTER_FECHA_FINAL:
        dispatch(fechaHastaFilter(value))
        break;

      case FILTER_PLANIFICACION_INICIO:
        dispatch(planificacionDesdeFilter(value))
        break;

      case FILTER_PLANIFICACION_FINAL:
        dispatch(planificacionHastaFilter(value))
        break;

      case FILTER_CANTIDAD_RESULTADOS:
        dispatch(cantidadResultadosFilter(value))
        break;

      case FILTER_ADD_METODO_ENTREGA:
        dispatch(metodoEntregaFilter(value))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-2 shadow-none bg-transparent">
      <iframe title='tracking' src={iframeData} style={{ display: 'none' }}></iframe>

      <ListadoHeader
        classes='border rounded-2'
        title={`${isPlanificacion ? CONSTANT.titlePlanificacion : CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetch()}
        handleClickClearFilter={() => handleFetch(true)}
        exportBtn={
          !isPlanificacion
            ? <DownloadFile
              url={CONSTANT.queryExportar}
              data={{ data: dataFormatter(filtros, isPlanificacion) }}
            />
            : <></>
        }
      >
        <>
          <FormInput
            labelText='NV'
            placeholder='Nota de venta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_NV)}
            value={nv}
          />

          <FormInput
            type='date'
            labelText='Planificación desde'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_PLANIFICACION_INICIO)}
            value={planificacion_desde}
          />

          <FormInput
            type='date'
            labelText='Planificación hasta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_PLANIFICACION_FINAL)}
            value={planificacion_hasta}
          />

          <SelectFilter
            labelText='Tipo de destino'
            placeholder='Seleccione'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_TIPO_DESTINO)}
            optionObj={selectFilter.destinos.map(({ nombre }) => ({ id: nombre, name: nombre }))}
            value={destino.split('-')[1] ?? ''}
          />

          <SelectFilter
            labelText='Método de entrega'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_METODO_ENTREGA)}
            optionObj={selectFilter.metodoEntrega?.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(metodos_entregas) ?? 'Seleccione'}
          />

          <FormInput
            type='number'
            labelText='Resultados a mostrar'
            placeholder='Cantidad'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_CANTIDAD_RESULTADOS)}
            value={limite}
          />

          <SelectFilter
            labelText='Código vendedor'
            placeholder='Seleccione'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_CODIGO_VENDEDOR)}
            optionObj={selectFilter.vendedores.map(({ codigo_vendedor, vendedor }) => ({ id: codigo_vendedor, name: vendedor }))}
            value={codigo_vendedor.split('-')[1] ?? ''}
          />

          {!isPlanificacion &&
            <>
              <FormInput
                type='number'
                labelText='NP'
                placeholder='Número pedido'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_NP)}
                value={np}
              />

              <FormInput
                type='number'
                labelText='Factura'
                placeholder='Número factura'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_FACTURA)}
                value={factura}
              />

              <FormInput
                labelText='RUT cliente'
                placeholder='Búsqueda'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_RUT_CLIENTE)}
                value={rut_cliente}
              />



              <SelectFilter
                labelText='Estatus'
                placeholder='Seleccione'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_ESTATUS)}
                optionObj={selectFilter.estatus.map(({ name }) => ({ id: name, name }))}
                value={estatus.split('-')[1] ?? ''}
              />

              <SelectFilter
                labelText='Comuna'
                placeholder='Seleccione'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_COMUNA_ENTREGA)}
                optionObj={selectFilter.comunas.map(({ nombre, cod_maxi }) => ({ id: cod_maxi, name: nombre }))}
                value={comuna_entrega_cod.split('-')[1] ?? ''}
              />

              <FormInput
                labelText='Punto de entrega'
                placeholder='Búsqueda'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_PUNTO_ENTREGA)}
                value={punto_entrega}
              />

              <FormInput
                type='number'
                labelText='DCX'
                placeholder='Búsqueda'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_DXC)}
                value={dxc}
              />

              <FormInput
                type='date'
                labelText='Desde'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_FECHA_INICIO)}
                value={fecha_desde}
              />

              <FormInput
                type='date'
                labelText='Hasta'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_FECHA_FINAL)}
                value={fecha_hasta}
              />
            </>
          }
        </>


        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={metodos_entregas} func={metodoEntregaFilter} title={'Métodos de entrega'} />
        </div>

        <hr className='my-1' />

      </ListadoHeader>
    </div>
  )
}

export default NotasVentasFiltros