import React from 'react'
import { useDispatch } from 'react-redux'

const FiltroAplicado = ({ array, func, title, separator = '-', size = 'col-12' }) => {
  const dispatch = useDispatch()

  return (
    <>
      {array.length > 0 &&
        <div className={`${size}`}>
          <p className='mt-75 mb-25'>{title}</p>
          <div className='d-flex gap-50 flex-wrap'>
            {array.length > 0 &&
              array.map(tag => (
                <button
                  key={`tag-${tag}`}
                  className='tag px-50 d-flex align-items-center text-black'
                  data-value={tag}
                  onClick={() => dispatch(func(tag, true))}
                ><span style={{ width: 'max-content' }}>{tag.split(separator)[1]}</span><b data-value={tag} className='ms-50'>x</b>
                </button>
              ))
            }
          </div>
        </div>
      }
    </>
  )
}

export default FiltroAplicado