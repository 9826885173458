import { idExtractor } from "../../../helpers/idExtractor"
import { isValidCreateForm, isValidUpdateForm } from "./validations"

export const createFormatter = (form) => {
  let formData = {
    pedido_id: Number(form?.pedido_id), // int
    cantidad_neumaticos: Number(form?.cantidad_neumaticos), // int
    estatus_id: idExtractor(form?.estatus_id), // int
    motivo_id: idExtractor(form?.motivo_id), // int
    monto_devuelto: Number(form.monto_devuelto ?? 0),  // int
    es_monto_devuelto_parcial: Number(form.es_monto_devuelto_parcial),  // int
    es_cantidad_neumaticos_parcial: Number(form.es_cantidad_neumaticos_parcial),  // int

    sin_devolucion: form.sin_devolucion, //bool
    sin_logistica: form.sin_logistica, //bool
    sin_bodega: form.sin_bodega, //bool

    numero_cuenta: String(form?.numero_cuenta)?.length ? Number(form.numero_cuenta) : null // int?
  }

  if (form?.banco_id?.length) formData = { ...formData, banco_id: idExtractor(form.banco_id) } // id?
  if (Number(form?.nueva_nota_venta) > 0) formData = { ...formData, nueva_nota_venta: Number(form.nueva_nota_venta) } // int?

  if (!isValidCreateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidCreateForm(formData) })
    return {}
  }

  return formData
}

export const updateFormatter = (form) => {
  let formData = {
    pedido_id: Number(form?.pedido_id), // int
    cantidad_neumaticos: Number(form?.cantidad_neumaticos), // int
    estatus_id: idExtractor(form?.estatus_id), // int
    motivo_id: idExtractor(form?.motivo_id), // int
    monto_devuelto: Number(form.monto_devuelto ?? 0),  // int
    es_monto_devuelto_parcial: Number(form.es_monto_devuelto_parcial),  // int
    es_cantidad_neumaticos_parcial: Number(form.es_cantidad_neumaticos_parcial),  // int

    numero_cuenta: String(form?.numero_cuenta)?.length ? Number(form.numero_cuenta) : null // int?
  }

  if (form?.banco_id?.length) formData = { ...formData, banco_id: idExtractor(form.banco_id) } // id?
  if (Number(form?.nueva_nota_venta) > 0) formData = { ...formData, nueva_nota_venta: Number(form.nueva_nota_venta) } // int?

  if (!isValidUpdateForm(formData).isValid) {
    console.error({ msg: "Error en validaciones", validations: isValidUpdateForm(formData) })
    return {}
  }

  return formData
}