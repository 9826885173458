import moment from 'moment';
import React, { useState } from 'react'


// React-router
import { Link } from 'react-router-dom';


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { clearFilterDevoluciones, resultadosDevoluciones } from '../../../redux/actions/devoluciones';


// Components
import Table from 'components/Table';
import { ModalBitacoraSolicitud } from 'components/Modal/Devoluciones/ModalBitacoraSolicitud';
import { ModalBitacoraLogistica } from 'components/Modal/Devoluciones/ModalBitacoraLogistica';
import { ModalBitacoraBodega } from 'components/Modal/Devoluciones/ModalBitacoraBodega';
import { ModalBitacoraDevolucion } from 'components/Modal/Devoluciones/ModalBitacoraDevolucion';
import { ModalBitacoraNC } from 'components/Modal/Devoluciones/ModalBitacoraNC';
import { ModalBitacoraGEV } from 'components/Modal/Devoluciones/ModalBitacoraGEV';
import DropdownWrapper from 'components/Dropdown/Wrapper';
import DropdownMenuLink from 'components/Dropdown/Link';


// Layouts
import AccordionTable from 'layout/AccordionTable';


// Services
import { getAllDevoluciones } from 'services/devoluciones/getAll.service';


// Helpers
import { filtrosFormatter } from 'components/AccordionFilter/Empresas';
import { ModalBitacoraNV } from 'components/Modal/Devoluciones/ModalBitacoraNV';


const CONSTANT = {
	permissionsPath: 'devoluciones',
	reduxSelector: 'devoluciones',
	pathUrl: '/devoluciones',
	title: 'Devoluciones',
	reduxClearFilters: clearFilterDevoluciones,
	reduxSetResults: resultadosDevoluciones,
}


export const DevolucionesTable = () => {
	const { resultados: response, filtros } = useSelector(state => state[CONSTANT.reduxSelector])
	const { showing_results } = useSelector(state => state.listedResults)
	const { user } = useSelector(state => state.auth)
	const dispatch = useDispatch()
	const [modalSolicitudState, setModalSolicitudState] = useState({ display: false, data: null })
	const [modalLogisticaState, setModalLogisticaState] = useState({ display: false, data: null })
	const [modalDevolucionState, setModalDevolucionState] = useState({ display: false, data: null })
	const [modalBodegaState, setModalBodegaState] = useState({ display: false, data: null })
	const [modalNCState, setModalNCState] = useState({ display: false, data: null })
	const [modalGEVState, setModalGEVState] = useState({ display: false, data: null })
	const [modalNVState, setModalNVState] = useState({ display: false, data: null })


	// FUNCION MANEJADORA DE PAGINACIÓN
	const handleUrl = (url) => {
		// console.log(url);
		dispatch(CONSTANT.reduxSetResults(null))

		getAllDevoluciones(showing_results, filtrosFormatter(filtros), url).then(res => {
			if (res.status >= 400) {
				// dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
				dispatch(CONSTANT.reduxSetResults([]))
				return
			}

			dispatch(CONSTANT.reduxSetResults(res))
		})
	}


	const handleModalSolicitud = (data) => {
		if (modalSolicitudState.display) {
			return setModalSolicitudState(() => ({ display: false, data: null }))
		}

		const utilData = {
			devolucion_id: data?.id,
			...data
		}

		setModalSolicitudState(() => ({ display: true, data: utilData }))
	}


	const handleModalDevolucion = (data) => {
		if (modalDevolucionState.display) {
			return setModalDevolucionState(() => ({ display: false, data: null }))
		}

		const utilData = {
			devolucion_id: data?.id,
			banco: data?.banco?.id ?? null,
			numero_cuenta: data?.numero_cuenta ?? null,
			...data?.bitacora_devolucion
		}

		setModalDevolucionState({ display: true, data: utilData })
	}


	const handleModalLogistica = (data) => {
		if (modalLogisticaState.display) {
			return setModalLogisticaState(() => ({ display: false, data: null }))
		}

		const utilData = {
			devolucion_id: data?.id,
			...data?.bitacora_logistica
		}

		setModalLogisticaState(() => ({ display: true, data: utilData }))
	}


	const handleModalBodega = (data) => {
		if (modalBodegaState.display) {
			return setModalBodegaState(() => ({ display: false, data: null }))
		}

		const utilData = {
			devolucion_id: data?.id,
			...data?.bitacora_bodega
		}

		setModalBodegaState({ display: true, data: utilData })
	}


	const handleModalNC = (data) => {
		if (modalNCState.display) {
			return setModalNCState(() => ({ display: false, data: null }))
		}

		const utilData = {
			devolucion_id: data?.id,
			nc: data?.notas_credito?.listado
		}

		setModalNCState({ display: true, data: utilData })
	}


	const handleModalGEV = (data) => {
		if (modalGEVState.display) {
			return setModalGEVState(() => ({ display: false, data: null }))
		}

		const utilData = {
			nc: data?.notas_credito,
			id: data?.id,
			gev: data?.gevs?.listado
		}

		setModalGEVState({ display: true, data: utilData })
	}


	const handleModalNV = (data) => {
		if (modalNVState.display) {
			return setModalNVState(() => ({ display: false, data: null }))
		}

		const utilData = {
			id: data?.id,
			nueva_nota_venta: data?.nueva_nota_venta
		}

		setModalNVState({ display: true, data: utilData })
	}


	return (
		<>
			<ModalBitacoraSolicitud
				data={modalSolicitudState.data}
				isOpen={modalSolicitudState.display}
				handleClose={handleModalSolicitud}
			/>

			<ModalBitacoraLogistica
				data={modalLogisticaState.data}
				isOpen={modalLogisticaState.display}
				handleClose={handleModalLogistica}
			/>

			<ModalBitacoraBodega
				data={modalBodegaState.data}
				isOpen={modalBodegaState.display}
				handleClose={handleModalBodega}
			/>

			<ModalBitacoraDevolucion
				data={modalDevolucionState.data}
				isOpen={modalDevolucionState.display}
				handleClose={handleModalDevolucion}
			/>

			<ModalBitacoraNC
				data={modalNCState.data}
				isOpen={modalNCState.display}
				handleClose={handleModalNC}
			/>

			<ModalBitacoraGEV
				data={modalGEVState.data}
				isOpen={modalGEVState.display}
				handleClose={handleModalGEV}
			/>

			<ModalBitacoraNV
				data={modalNVState.data}
				isOpen={modalNVState.display}
				handleClose={handleModalNV}
			/>


			<AccordionTable title={`${CONSTANT.title} (${response?.data?.length ?? 0} resultados)`}>
				<Table response={response} handlePagination={handleUrl}>
					<thead className="table-light position-sticky top-0 w-100 z-100 shadow">
						<tr role="row">
							<th>ID</th>
							<th>Pedido</th>
							<th>Neumáticos</th>
							<th>Monto</th>
							<th>Creación</th>
							<th className="w-max">Acciones</th>
						</tr>
					</thead>

					<tbody>
						{response?.data?.length
							? response.data?.map((data) => {
								return (
									<React.Fragment key={`devolucion-data-${data?.id}`}>
										{/* MARK: LINEA BITACORA */}
										<tr>
											<td colSpan={6}>
												<div className='linea-bitacora-container'>
													<button
														className='linea-bitacora-btn'
														onClick={() => handleModalSolicitud(data)}
														style={{ backgroundColor: "#28C76F" }}
													>
														Solicitud
													</button>

													<button
														className='linea-bitacora-btn'
														onClick={() => handleModalDevolucion(data)}
														style={{ backgroundColor: data?.bitacora_devolucion?.estado?.color_hex }}
														disabled={!data?.bitacora_devolucion}
													>
														Devolución
													</button>

													<button
														className='linea-bitacora-btn'
														onClick={() => handleModalLogistica(data)}
														style={{ backgroundColor: data?.bitacora_logistica?.estado?.color_hex }}
														disabled={!data?.bitacora_logistica}
													>
														Rev. Logistica
													</button>

													<button
														className='linea-bitacora-btn'
														onClick={() => handleModalBodega(data)}
														style={{ backgroundColor: data?.bitacora_bodega?.estado?.color_hex }}
														disabled={!data?.bitacora_bodega}
													>
														Rev. Bodega
													</button>

													<button
														className='linea-bitacora-btn'
														onClick={() => handleModalNC(data)}
														style={{ backgroundColor: data?.notas_credito?.listado?.length && data?.notas_credito?.color_hex }}
													// disabled={!data?.notas_credito?.length}
													>
														NC
													</button>

													<button
														className='linea-bitacora-btn'
														onClick={() => handleModalGEV(data)}
														style={{ backgroundColor: data?.gevs?.listado?.length && "#28C76F" }}
													>
														GEV
													</button>

													<button
														className='linea-bitacora-btn'
														onClick={() => handleModalNV(data)}
														style={{ backgroundColor: data?.nueva_nota_venta && "#28C76F" }}
													>
														NV
													</button>
												</div>
											</td>
										</tr >


										{/* MARK: LINEA DATOS */}
										< tr className="odd" key={`user-${data.id}-${data.titulo}`
										} style={{ borderBottom: '2px solid #aaa' }}>

											<td className='col-1'>{data?.id}</td>

											<td><Link target='_blank' rel='noreferrer' to={`/pedidos/detalle/${data?.pedido_id}`}>{data?.pedido_id ?? "N/A"}</Link></td>

											<td className='col-3'>
												<div className='d-flex flex-column gap-25'>
													<span>Es cantidad de neumáticos parcial: <b>{data?.es_cantidad_neumaticos_parcial ? 'Sí' : 'No'}</b></span>
													<span className='fs-5'>Cantidad de neumáticos devueltos: <b>{data?.cantidad_neumaticos}</b></span>
												</div>
											</td>

											<td className='col-3'>
												<div className='d-flex flex-column gap-25'>
													<span>Es monto devuelto parcial: <b>{data?.es_monto_devuelto_parcial ? 'Sí' : 'No'}</b></span>
													<span className='fs-5'>Monto devuelto: <b>{data?.cantidad_neumaticos}</b></span>
												</div>
											</td>

											<td className='col-2 fw-bold'>{moment(data?.created_at).format('DD-MM-YYYY')}</td>

											<td className='col-1'>
												<DropdownWrapper icon={"MoreVertical"} classes="w-fit mx-auto" >
													{user.permisos[`${CONSTANT.permissionsPath}.detalle`] &&
														<DropdownMenuLink text='Detalle' href={`${CONSTANT.pathUrl}/detalle/${data.id}`} icon="Table" />
													}

													{user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
														<DropdownMenuLink text='Editar' href={`${CONSTANT.pathUrl}/update/${data.id}`} icon="Settings" />
													}

													{/* {user.permisos[`${CONSTANT.permissionsPath}.eliminar`] &&
													<DropdownMenuLink text='Borrar' icon="X" onClick={() => handleDelete(data.id, response.path + '?page=' + response.current_page + `&limit=${showing_results}`)} />
												} */}
												</DropdownWrapper>
											</td>
										</tr>
									</React.Fragment>
								)
							})
							: <tr><td className="text-center fw-bolder fs-2 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
						}
					</tbody>
				</Table >
			</AccordionTable >
		</>
	)
}