import { Navigate, Route, Routes } from "react-router-dom"
import { useSelector } from "react-redux";



// Routers
import RespuestasRouter from "./Garantias/RespuestasRouter";



// Views
import Detalle from 'views/Garantias/Detalle';
import Listado from 'views/Garantias/Listado';
import Form from "views/Garantias/Form";
import FormPlanilla from "views/Garantias/Planilla/Form";
import CausasRouter from "./Garantias/CausasRouter";
import DanosRouter from "./Garantias/DanosRouter";
import ConclusionesRouter from "./Garantias/ConclusionesRouter";
import RecomendacionesRouter from "./Garantias/RecomendacionesRouter";



const GarantiasRouter = () => {
   const { user } = useSelector(state => state.auth)

   return (
      <Routes>
         <Route path='/' element={<Listado />} />

         {user.permisos['garantias.crear'] &&
            <Route path='/create' element={<Form />} />
         }

         {user.permisos['garantias.detalle'] &&
            <Route path='/detalle/:id' element={<Detalle />} />
         }

         {user.permisos['garantias.editar'] &&
            <Route path='/update/:id' element={<Form />} />
         }

         <Route path='/planilla/update/:id' element={<FormPlanilla />} />

         <Route path='/conclusiones/*' element={<ConclusionesRouter />} />

         <Route path='/recomendaciones/*' element={<RecomendacionesRouter />} />

         <Route path='/respuestas/*' element={<RespuestasRouter />} />

         <Route path='/causas/*' element={<CausasRouter />} />

         <Route path='/danos/*' element={<DanosRouter />} />

         <Route path='*' element={<Navigate to='/home' replace />} />
      </Routes>
   )
}

export default GarantiasRouter;