export const isValidCreateForm = (form) => {
  const stringValuesArr = ['banco_id', 'estatus_id', 'motivo_id', 'numero_cuenta']
  const intValuesArr = ['cantidad_neumaticos', 'monto_devuelto', 'nueva_nota_venta', 'pedido_id']
  const boolValuesArr = ['es_cantidad_neumaticos_parcial', 'es_monto_devuelto_parcial', 'sin_devolucion', 'sin_logistica', 'sin_bodega']
  const required = ['estatus_id', 'motivo_id', 'cantidad_neumaticos', 'pedido_id', 'es_cantidad_neumaticos_parcial', 'es_monto_devuelto_parcial']

  let result = { isValid: true }

  Object.entries(form).forEach(([k, v]) => {
    if (required.includes(k) && !v?.length) {
      result = {
        ...result,
        [k]: `[required]`,
      }
    }

    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], length: ${v?.length ?? 0}`,
      }
      return
    }

    // int
    if (intValuesArr.includes(k) && typeof Number(v) === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
      }
      return
    }

    // bool
    if (boolValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
      }
      return
    }
  })

  return result
}

export const isValidUpdateForm = (form) => {
  const stringValuesArr = ['banco_id', 'estatus_id', 'motivo_id', 'numero_cuenta']
  const intValuesArr = ['cantidad_neumaticos', 'monto_devuelto', 'nueva_nota_venta', 'pedido_id']
  const boolValuesArr = ['es_cantidad_neumaticos_parcial', 'es_monto_devuelto_parcial', 'sin_devolucion', 'sin_logistica', 'sin_bodega']
  const required = ['estatus_id', 'motivo_id', 'cantidad_neumaticos', 'pedido_id', 'es_cantidad_neumaticos_parcial', 'es_monto_devuelto_parcial']

  let result = { isValid: true }

  Object.entries(form).forEach(([k, v]) => {
    if (required.includes(k) && !v?.length) {
      result = {
        ...result,
        [k]: `[required]`,
      }
    }

    // strings
    if (stringValuesArr.includes(k) && typeof v === 'string' && !v?.length) {
      result = {
        ...result,
        [k]: `[${typeof v}], length: ${v?.length ?? 0}`,
      }
      return
    }

    // int
    if (intValuesArr.includes(k) && typeof Number(v) === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
      }
      return
    }

    // bool
    if (boolValuesArr.includes(k) && typeof v === 'number' && v < 0) {
      result = {
        ...result,
        [k]: `[${typeof v}], value: ${v}`,
      }
      return
    }
  })

  return result
}