import { BASE_URL, PATH_URL } from "..";

export const getOne = {
   pais: (id) => BASE_URL + PATH_URL + `/paises/${id}`,
   cuenta_caja: (id) => BASE_URL + PATH_URL + `/cuentas-caja/${id}`,
   bodega: (id) => BASE_URL + PATH_URL + `/bodegas/${id}`,
   aprobar_empresa: (id) => BASE_URL + PATH_URL + `/empresas/aprobar/${id}`,
   empresas: (id) => BASE_URL + PATH_URL + `/empresas/${id}`,
   servicios: (id) => BASE_URL + PATH_URL + `/servicios/${id}`,
   devoluciones: (id) => BASE_URL + PATH_URL + `/devoluciones/${id}`,
   parametros_recargos: (id) => BASE_URL + PATH_URL + `/parametros-recargos/${id}`,
   historialCambios: (id) => BASE_URL + PATH_URL + `/historial/${id}`,
   productos_proveedor: (id) => BASE_URL + PATH_URL + `/productos-proveedor/${id}`,
   productos_propios_proveedor: (id) => BASE_URL + PATH_URL + `/productos-propios-proveedor/${id}`,
   busquedas_clientes: (id) => BASE_URL + PATH_URL + `/busquedas-clientes/${id}`,
   ciudades: (id) => BASE_URL + PATH_URL + `/ciudades/${id}`,
   garantias: (id) => BASE_URL + PATH_URL + `/garantias/${id}`,
   garantia_respuestas: (id) => BASE_URL + PATH_URL + `/garantia-respuestas/${id}`,
   garantia_conclusiones: (id) => BASE_URL + PATH_URL + `/garantia-conclusiones/${id}`,
   garantia_recomendaciones: (id) => BASE_URL + PATH_URL + `/garantia-recomendaciones/${id}`,
   garantia_causas: (id) => BASE_URL + PATH_URL + `/garantia-causas/${id}`,
   contactos_punto_entrega: (id) => BASE_URL + PATH_URL + `/contactos-punto-entrega/${id}`,
   bodegas_punto_entrega: (id) => BASE_URL + PATH_URL + `/bodegas-punto-entrega/${id}`,
   eventos: (id) => BASE_URL + PATH_URL + `/eventos/${id}`,
   distritos: (id) => BASE_URL + PATH_URL + `/distritos/${id}`,
   notas_ventas: (id) => BASE_URL + PATH_URL + `/erp/notas-ventas/${id}`,
   pedidos: (id) => BASE_URL + PATH_URL + `/pedidos/${id}`,
   agendamientos: (id) => BASE_URL + PATH_URL + `/agendamientos/${id}`,
   producto_erp: (sku) => BASE_URL + PATH_URL + `/erp/productos/obtener/sql/${sku}`,
   ofertas: (id) => BASE_URL + PATH_URL + `/ofertas/${id}`,
   adicionales: (id) => BASE_URL + PATH_URL + `/adicionales/${id}`,
   agendas: (id) => BASE_URL + PATH_URL + `/agendas/${id}`,
   usuarios: (id) => BASE_URL + PATH_URL + `/usuarios/${id}`,
   comunas: (id) => BASE_URL + PATH_URL + `/comunas/${id}`,
   categoria: (id) => BASE_URL + PATH_URL + `/categorias/${id}`,
   cinta_informativa: (id) => BASE_URL + PATH_URL + `/cinta-informativa/${id}`,
   tipos_elementos: (id) => BASE_URL + PATH_URL + `/tipos-elementos/${id}`,
   categorias_faq: (id) => BASE_URL + PATH_URL + `/categorias-faq/${id}`,
   subcategorias_faq: (id) => BASE_URL + PATH_URL + `/subcategorias-faq/${id}`,
   componentes: (id) => BASE_URL + PATH_URL + `/componentes/${id}`,
   banners: (id) => BASE_URL + PATH_URL + `/banners/${id}`,
   tipos_facturacion: (id) => BASE_URL + PATH_URL + `/tipos-facturacion/${id}`,
   menus: (id) => BASE_URL + PATH_URL + `/menus/${id}`,
   cotizaciones: (id) => BASE_URL + PATH_URL + `/cotizaciones/${id}`,
   tipos_atencion: (id) => BASE_URL + PATH_URL + `/tipos-atencion/${id}`,
   dias: (id) => BASE_URL + PATH_URL + `/dias/${id}`,
   productos: (id) => BASE_URL + PATH_URL + `/productos/${id}`,
   metadatos: (id) => BASE_URL + PATH_URL + `/metadatos/${id}`,
   formas_pago: (id) => BASE_URL + PATH_URL + `/formas-pago/${id}`,
   estatus_pedido: (id) => BASE_URL + PATH_URL + `/estatus-pedido/${id}`,
   divisas: (id) => BASE_URL + PATH_URL + `/divisas/${id}`,
   etiquetas: (id) => BASE_URL + PATH_URL + `/etiquetas/${id}`,
   roles: (id) => BASE_URL + PATH_URL + `/roles/${id}`,
   proveedores: (id) => BASE_URL + PATH_URL + `/proveedores/${id}`,
   codigos_descuento: (id) => BASE_URL + PATH_URL + `/codigos-descuento/${id}`,
   proveedores_categoria: (id) => BASE_URL + PATH_URL + `/proveedores-categorias/${id}`,
   unidades: (id) => BASE_URL + PATH_URL + `/unidades/${id}`,
   tipos_destino: (id) => BASE_URL + PATH_URL + `/tipos-destino/${id}`,
   metodos_pago: (id) => BASE_URL + PATH_URL + `/metodos-pago/${id}`,
   metodos_entrega: (id) => BASE_URL + PATH_URL + `/metodos-entrega/${id}`,
   puntos_entrega: (id) => BASE_URL + PATH_URL + `/puntos-entrega/${id}`,
   claves_atributos: (id) => BASE_URL + PATH_URL + `/claves-atributos/${id}`,
   valores_atributos: (id) => BASE_URL + PATH_URL + `/valores-atributos/${id}`,
   criterios_comparacion: (id) => BASE_URL + PATH_URL + `/criterios-comparacion/${id}`,
   faq: (id) => BASE_URL + PATH_URL + `/preguntas-frecuentes/${id}`,
   pasos_compra: (id) => BASE_URL + PATH_URL + `/pasos-compra/${id}`,
   factores_diferenciadores: (id) => BASE_URL + PATH_URL + `/factores-diferenciadores/${id}`,
   terminos_condiciones: (id) => BASE_URL + PATH_URL + `/terminos-condiciones/${id}`,
   region: (id) => BASE_URL + PATH_URL + `/regiones/${id}`,
}