// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";


// React-router
import { useNavigate, useParams, useSearchParams } from "react-router-dom";


// Endpoints
import { useEffect, useState } from "react";
import SelectFilter from "../../components/SelectFilter";
import CustomLoader from "../../components/CustomLoader";
import { getOne } from "../../endpoints/getOne";
import CheckBoxInput from "../../components/CheckboxInput";
import { createFormatter, updateFormatter } from "./helpers/formFormatter";
import { responseToasterContent } from "../../helpers/toasterContent";
import { successResponse } from "../../helpers/successResponse";
import { getDetailedDevolucion } from "../../services/devoluciones/getDetail.service";
import { getAllEstatus } from "../../services/devoluciones/estatus/getAll.service";
import { getAllMotivos } from "../../services/devoluciones/motivos/getAll.service";
import { getAllBancos } from "../../services/devoluciones/bancos/getAll.service";
import { updateDevolucion } from "../../services/devoluciones/update.service";
import { createDevolucion } from "../../services/devoluciones/create.service";
import { getDetailedPedido } from "services/pedidos/getDetail.service";
import { PrecioFormatter } from "helpers/precioFormatter";


const CONSTANT = {
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  redirectUrl: '/devoluciones',
  componentTitle: id => `${id ? 'Editar' : 'Generar'} Devolución`,
  title: 'Devoluciones',

  fecthData: getOne.devoluciones,

  selectFilter: {
    pedidos: [],
    bancos: [],
    motivos: [],
  },

  form: {
    pedido_id: '', // int
    estatus_id: '', // int
    motivo_id: '', // int
    cantidad_neumaticos: '', // int
    monto_devuelto: '', // int
    es_monto_devuelto_parcial: 0, // bool
    es_cantidad_neumaticos_parcial: 0, // bool
    sin_devolucion: false,
    sin_logistica: false,
    sin_bodega: false,

    // optional
    banco_id: '', // int
    numero_cuenta: '', // int
    nueva_nota_venta: '', // int
  },

  utils: {
    monto_total: null
  }
}


export const handleSelectsData = async () => {
  const estatus = await getAllEstatus().then(res => {
    if (res.status >= 400) return []
    return [...res.data.data]
  })

  const motivos = await getAllMotivos().then(res => {
    if (res.status >= 400) return []
    return [...res.data.data]
  })

  const bancos = await getAllBancos().then(res => {
    if (res.status >= 400) return []
    return [...res.data.data]
  })

  return { estatus, motivos, bancos }
}


const fetchPedido = async (id) => {
  if (!id) return

  return await getDetailedPedido(id)
    .then(res => res)
    .catch(err => err)
}


const priceFormat = (v) => new PrecioFormatter(v).producto()


export const Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [params] = useSearchParams()
  const [form, setForm] = useState(CONSTANT.form)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [isConsulting, setIsConsulting] = useState(false)
  const [utilData, setUtilData] = useState(CONSTANT.utils)


  const handleOnBlurPedido = async (e, id) => {
    await fetchPedido(id ?? form?.pedido_id)
      .then(res => {
        setForm(prev => ({
          ...prev,
          monto_devuelto: res?.data?.monto_total_pedido ?? '',
          cantidad_neumaticos: res?.data?.total_productos ?? ''
        }))

        setUtilData((prev) => ({
          ...prev,
          monto_total: res?.data?.monto_total_pedido
        }))
        dispatch(fireToaster(responseToasterContent(res)))
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // EFECTO QUE SETEA LOS DATOS PARA EL FORMULARIO DE EDICION
  useEffect(() => {
    if (!id
      || !selectFilter?.estatus?.length
      || !selectFilter?.motivos?.length
      || !selectFilter?.bancos?.length) return

    getDetailedDevolucion(id).then(({ data }) => {
      const defaultNullObj = { id: null, nombre: null }

      const { id: estatusId, nombre: estatusNombre } = selectFilter.estatus.find(({ id }) => id === data?.estatus_id) ?? defaultNullObj
      const { id: motivoId, nombre: motivoNombre } = selectFilter.motivos.find(({ id }) => id === data?.motivo_id) ?? defaultNullObj
      const banco = selectFilter.bancos.find(({ id }) => id === data?.banco_id) ?? defaultNullObj

      setForm((prev) => ({
        ...prev,
        ...data,
        banco_id: `${banco?.id ?? '*NULL*'}-${banco?.nombre ?? 'Sin selección'}`,
        motivo_id: `${motivoId ?? '*NULL*'}-${motivoNombre ?? 'Sin selección'}`,
        estatus_id: `${estatusId ?? '*NULL*'}-${estatusNombre ?? 'Sin selección'}`,
        monto_devuelto: data?.monto_devuelto?.length ? Number(data?.monto_devuelto) : ''
      }))
    })

  }, [id, selectFilter.estatus, selectFilter.motivos, selectFilter.bancos])


  // EFECTO QUE SETEA LA DATA PARA LOS SELECTFILTERS
  useEffect(() => {
    handleSelectsData().then(x => setSelectFilter(x))

    return () => {
      setForm(CONSTANT.form)
    }
  }, [])


  // EFECTO QUE DETECTA EL ID DEL PEDIDO GENERADO DESDE EL DETALLE 
  useEffect(() => {
    for (const [k, v] of params) {
      if (k === 'pedido_id') {
        setForm(prev => ({ ...prev, [k]: v }))

        setIsConsulting(true)

        fetchPedido(v)
          .then(res => {
            setForm(prev => ({
              ...prev,
              monto_devuelto: Number(res?.data?.monto_total_pedido) ?? '',
              cantidad_neumaticos: Number(res?.data?.total_productos) ?? ''
            }))

            dispatch(fireToaster(responseToasterContent(res)))
          })
          .catch(err => {
            dispatch(fireToaster(responseToasterContent(err)))
          })
          .finally(() => {
            setIsConsulting(false)
          })
      }
    }
  }, [params, dispatch])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCION SETEAR MONTO TOTAL
  const setMontoTotal = (e) => {
    e.preventDefault()

    setForm(prev => ({
      ...prev,
      monto_devuelto: utilData.monto_total
    }))
  }


  // FUNCIÓN MANEJADORA QUE CREA/MODIFICA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()
    setIsConsulting(true)

    if (id) {
      updateDevolucion(id, updateFormatter({ ...form }))
        .then(res => {
          dispatch(fireToaster(responseToasterContent(res)))
          if (successResponse(res)) navigate(CONSTANT.redirectUrl)
        })
        .finally(() =>
          setIsConsulting(false)
        )

      return
    }

    createDevolucion(createFormatter({ ...form }))
      .then(res => {
        dispatch(fireToaster(responseToasterContent(res)))
        if (successResponse(res)) navigate(CONSTANT.redirectUrl)
      })
      .finally(() =>
        setIsConsulting(false)
      )

    return
  }


  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='row'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} className='col-6' />

          {params?.size > 0 &&
            <Button text={`Volver al pedido #${form?.pedido_id}`} icon='File' style='flat' isLink url={`/pedidos/detalle/${form?.pedido_id}`} className='col-6' />
          }
        </div>

        {id && !form?.id &&
          <CustomLoader blocking={'partial'} />
        }

        <CustomForm size="" title={CONSTANT.componentTitle(id)}>

          <div className="row">

            <CheckBoxInput
              name='sin_devolucion'
              value={form.sin_devolucion}
              labelText='Marcar bitácora de devolución como completada'
              handleValue={handleInputChange}
              classes='col-12 my-auto'
            />

            <CheckBoxInput
              name='sin_logistica'
              value={form.sin_logistica}
              labelText='Marcar bitácora de logística como completada'
              handleValue={handleInputChange}
              classes='col-12 my-auto'
            />

            <CheckBoxInput
              name='sin_bodega'
              value={form.sin_bodega}
              labelText='Marcar bitácora de bodega como completada'
              handleValue={handleInputChange}
              classes='col-12 my-auto'
            />

            <hr className="my-1" />

            <FormInput
              labelText='* # Pedido'
              placeholder='1234'
              name='pedido_id'
              value={form.pedido_id}
              isDisabled={params?.size > 0 || id}
              handleValue={handleInputChange}
              onBlur={handleOnBlurPedido}
              size='col-12'
              sizeDesk='col-md-6'
              type='number'
            />

            {/* <SelectFilter
                labelText='* Estatus'
                name='estatus_id'
                value={form.estatus_id?.split('-')[1] ?? 'Seleccione'}
                handleValue={handleInputChange}
                optionObj={selectFilter?.estatus?.map(({ id, nombre }) => ({ id, name: nombre })) ?? []}
                size='col-12'
                sizeDesk='col-md-6'
              /> */}

            <SelectFilter
              labelText='* Motivo'
              name='motivo_id'
              value={form.motivo_id?.split('-')[1] ?? 'Seleccione'}
              handleValue={handleInputChange}
              optionObj={selectFilter?.motivos?.map(({ id, nombre }) => ({ id, name: nombre })) ?? []}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <hr className="my-1" />

            <CheckBoxInput
              name='es_monto_devuelto_parcial'
              value={form.es_monto_devuelto_parcial}
              labelText='Monto devuelto parcial'
              handleValue={handleInputChange}
              classes='col-6 my-auto'
            />

            <FormInput
              labelText='* Monto devuelto ($CLP)'
              placeholder={utilData.monto_total}
              name='monto_devuelto'
              value={form.monto_devuelto}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
              isDisabled={!form.es_monto_devuelto_parcial}
              type='number'
            />

            {!id &&
              <>
                <div className="col-6" />

                <div className="col-6">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>Monto total: {priceFormat(utilData?.monto_total) ?? 'N/A'}</span>

                    <Button text="Usar total" disabled={!utilData.monto_total} onClick={setMontoTotal} />
                  </div>
                </div>
              </>
            }

            <CheckBoxInput
              name='es_cantidad_neumaticos_parcial'
              value={form.es_cantidad_neumaticos_parcial}
              labelText='Cantidad neumático parcial'
              handleValue={handleInputChange}
              classes='col-6 my-auto'
            />

            <FormInput
              labelText='* Cant. Neumáticos'
              placeholder='1234'
              name='cantidad_neumaticos'
              value={form.cantidad_neumaticos}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
              isDisabled={!form.es_cantidad_neumaticos_parcial}
              type='number'
            />

            <hr className="my-1" />

            <FormInput
              labelText='Num. Cuenta'
              placeholder='0000000'
              name='numero_cuenta'
              value={form.numero_cuenta}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
              type='number'
            />

            <SelectFilter
              labelText='Banco'
              name='banco_id'
              value={form.banco_id?.split('-')[1] ?? 'Seleccione'}
              handleValue={handleInputChange}
              optionObj={selectFilter?.bancos?.map(({ id, nombre }) => ({ id, name: nombre })) ?? []}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <FormInput
              labelText='Nueva nota de venta'
              placeholder='123'
              name='nueva_nota_venta'
              value={form.nueva_nota_venta}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
              type='number'
            />
          </div>

          <Button
            type="submit"
            text={id ? 'Actualizar' : "Crear"}
            color="primary"
            disabled={isConsulting}
            icon={id ? 'Check' : "Plus"}
            onClick={(e) => handleFetch(e)}
          />
        </CustomForm>
      </div >
    </div >
  );
};
