import { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'components/Modal';
import { getAll } from 'endpoints/getAll';
import SelectFilter from 'components/SelectFilter';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { fireToaster } from 'redux/actions/toaster';
import { idExtractor } from 'helpers/idExtractor';
import { BASE_URL, PATH_URL } from 'endpoints';
import { useParams } from 'react-router-dom';
import FormInput from 'components/FormInput';
import CheckBoxInput from 'components/CheckboxInput';
import { getAllGarantiasRecomendaciones } from 'services/garantias/recomendaciones/getAll.service';
import { getAllGarantiasConclusiones } from 'services/garantias/concluciones/getAll.service';


const CONSTANT = {
  title: 'Garantía',
  componentTitle: 'Completar garantía',
  redirectUrl: '/garantias',
  queryUrl: (id) => `${BASE_URL}${PATH_URL}/garantias/completar/${id}`,
  fetchResultados: getAll.garantia_resultados,
  fetchRespuestas: getAll.garantia_respuestas,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  initialFormState: {
    respuesta_id: '',
    resultado_id: '',
    conclusion_id: '',
    recomendacion_id: '',
    banda_residual: '',
    porcentaje_bonificacion: '',
    envio_fabrica: false,
    codigo_evaluador: '',
  },
  selectFilterInitialState: {
    respuestas: [],
    resultados: [],
  }
}


const handleSelectFiltersData = async () => {
  const respuestas = await axios(CONSTANT.fetchRespuestas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const resultados = await axios(CONSTANT.fetchResultados,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const recomendaciones = await getAllGarantiasRecomendaciones()
    .then(({ data }) => [CONSTANT.unselectedObject, ...data.data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const conclusiones = await getAllGarantiasConclusiones()
    .then(({ data }) => [CONSTANT.unselectedObject, ...data.data] ?? [CONSTANT.unselectedObject])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { respuestas, resultados, conclusiones, recomendaciones }
}


const ModalCompletar = ({ isOpen, handleClose, handleGet }) => {
  const dispatch = useDispatch()

  const { id: garantiaId } = useParams()

  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(CONSTANT.initialFormState);


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // MANEJADOR DE LOS INPUTS
  const handleInputChange = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }


  const handleFetch = (e) => {
    e.preventDefault()

    if (!form.respuesta_id || form.respuesta_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'La respuesta es obligatoria', icon: 'info' }))
    if (!form.resultado_id || form.resultado_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'el resultado es obligatorio', icon: 'info' }))

    let data = {
      resultado_id: idExtractor(form.resultado_id),
      respuesta_id: idExtractor(form.respuesta_id),
      conclusion_id: idExtractor(form.conclusion_id),
      recomendacion_id: idExtractor(form.recomendacion_id),
      banda_residual: form.banda_residual,
      porcentaje_bonificacion: Number(form.porcentaje_bonificacion),
      envio_fabrica: form.envio_fabrica ? true : false,
      codigo_evaluador: form.codigo_evaluador,
    }

    axios(CONSTANT.queryUrl(garantiaId), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(res => {

        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: #${garantiaId} actualizada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        handleGet(garantiaId)
        handleClose()
      })
      .catch(err => {
        console.log(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <Modal display={isOpen} handleValue={handleClose}>
      <h3 className='text-center'>Formulario completación de garantía</h3>

      <form className='row' onSubmit={handleFetch}>
        <div className='d-flex justify-content-end py-1'>
          <CheckBoxInput
            labelText="Envío a fábrica"
            name="envio_fabrica"
            value={form?.envio_fabrica}
            handleValue={handleInputChange}
          />
        </div>

        <SelectFilter
          labelText="* Resultado"
          name="resultado_id"
          value={form?.resultado_id?.split('-')[1] ?? 'Seleccione'}
          handleValue={handleInputChange}
          optionObj={selectFilter.resultados?.map(({ resultado, nombre, id }) => ({ name: resultado ?? nombre, id }))}
          size="col-12"
          sizeDesk='col-md-6'
        />

        <SelectFilter
          labelText="* Respuesta"
          name="respuesta_id"
          value={form?.respuesta_id?.split('-')[1] ?? 'Seleccione'}
          handleValue={handleInputChange}
          optionObj={selectFilter.respuestas?.map(({ titulo, nombre, id }) => ({ name: titulo ?? nombre, id }))}
          size="col-12"
          sizeDesk='col-md-6'
        />

        <SelectFilter
          labelText="* Conclusión"
          name="conclusion_id"
          value={form?.conclusion_id?.split('-')[1] ?? 'Seleccione'}
          handleValue={handleInputChange}
          optionObj={selectFilter.conclusiones?.map(({ titulo, nombre, id }) => ({ name: titulo ?? nombre, id }))}
          size="col-12"
          sizeDesk='col-md-6'
        />

        <SelectFilter
          labelText="* Recomendación"
          name="recomendacion_id"
          value={form?.recomendacion_id?.split('-')[1] ?? 'Seleccione'}
          handleValue={handleInputChange}
          optionObj={selectFilter.recomendaciones?.map(({ titulo, nombre, id }) => ({ name: titulo ?? nombre, id }))}
          size="col-12"
          sizeDesk='col-md-6'
        />

        <FormInput
          labelText="Banda residual"
          name='banda_residual'
          handleValue={handleInputChange}
          value={form?.banda_residual}
          size="col-12"
          sizeDesk="col-md-4"
          placeholder='Banda residual'
        />

        <FormInput
          labelText="% Bonificación"
          name='porcentaje_bonificacion'
          handleValue={handleInputChange}
          value={form?.porcentaje_bonificacion}
          size="col-12"
          sizeDesk="col-md-4"
          type='number'
          placeholder='Porcentaje'
        />

        <FormInput
          labelText="Código del evaluador"
          name='codigo_evaluador'
          handleValue={handleInputChange}
          value={form?.codigo_evaluador}
          size="col-12"
          sizeDesk="col-md-4"
          placeholder='Código del evaluador'
        />

        <div className='d-flex justify-content-end'>
          <Button type='submit' text='Actualizar' />
        </div>
      </form>
    </Modal>
  );
}



export default ModalCompletar;