import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTROS_PERSISTENTES_GARANTIAS_RECOMENDACIONES,
   RESULTADOS_GARANTIAS_RECOMENDACIONES,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosGarantiasRecomendaciones = (data) => ({ type: RESULTADOS_GARANTIAS_RECOMENDACIONES, payload: data })

export const filtrosPersistentesGarantiasRecomendaciones = (data) => ({ type: FILTROS_PERSISTENTES_GARANTIAS_RECOMENDACIONES, payload: data })

export const clearFilterGarantiasRecomendaciones = () => ({ type: FILTER_CLEAR });
