import axios from "axios";


// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// Hooks / Helpers
import { useForm } from "../../hooks/useForm";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import TextareaInput from "../../components/TextareaInput";
import { BASE_URL, PATH_URL } from "../../endpoints";
import { useEffect, useState } from "react";
import SelectFilter from "../../components/SelectFilter";
import { getAllBodegas } from "services/bodegas/getAll.service";
import { idExtractor } from "helpers/idExtractor";


const CONSTANT = {
  redirectUrl: '/generales/metodos-entrega',
  componentTitle: 'Crear método de entrega',
  title: 'Métodos de entrega',
  queryUrl: create.metodos_entrega,

  nullObject: { id: '*NULL*', nombre: 'Error' },
  unselectedObject: { id: '-', nombre: 'Sin selección' },

  fetchModosEntrega: BASE_URL + PATH_URL + `/erp/modos-despachos/obtener`,

  initialformState: {
    nombre: '',
    descripcion: '',
    cod_erp: '',
    bodega_instalacion_id: '',
    estatus: true
  },

  selectFilter: {
    modosDespacho: [],
    bodegas: []
  }
}


const fetchSelectFiltersData = async () => {

  return await Promise.all([
    axios(CONSTANT.fetchModosEntrega,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => ({ modosDespacho: [...data?.data] ?? [] }))
      .catch(err => {
        console.error(err)
        return ({ modosDespacho: [] })
      }),

    getAllBodegas()
      .then(({ data }) => ({ bodegas: [CONSTANT.unselectedObject, ...data] }))
      .catch(err => {
        console.error(err)
        return ({ bodegas: [] })
      }),
  ])
    .then(res => {
      let r = {}
      res.forEach((x) => {
        Object.entries(x).forEach(([k, v]) => r = { ...r, [k]: [...v] })
      })
      return r
    })
}


const Create = () => {
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const navigate = useNavigate()
  const [{
    nombre,
    descripcion,
    estatus,
    bodega_instalacion_id,
    cod_erp
  }, handleInputChange, reset] = useForm(CONSTANT.initialformState);


  // EFFECTO QUE SETEA LA DATA DE LOS SELECTFILTER
  useEffect(() => {
    fetchSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e) => {
    e.preventDefault()

    let data = {
      nombre,
      descripcion,
      bodega_instalacion_id: idExtractor(bodega_instalacion_id),
      cod_erp: cod_erp?.split('-')?.[0],
      estatus: Number(estatus) ? true : false
    }

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} creado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <div className="row">
      < div className="col col-md-6 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        <CustomForm size="" title={CONSTANT.componentTitle}>
          <div className="row">
            <Switch
              labelText='Estatus'
              name='estatus'
              value={estatus}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
            />

            <FormInput
              labelText='* Nombre'
              name='nombre'
              value={nombre}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <SelectFilter
              labelText='Modo despacho'
              name='cod_erp'
              optionObj={selectFilter.modosDespacho.map(({ cod_erp, nombre }) => ({ id: cod_erp ?? '*NULL*', name: nombre ?? 'ERROR' }))}
              value={cod_erp?.split('-')?.[1] ?? 'Sin selección'}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-6'
            />

            <SelectFilter
              labelText='Bodega instalación'
              name='bodega_instalacion_id'
              optionObj={selectFilter.bodegas.map(({ id, codigo, nombre }) => ({ id: id ?? '*NULL*', name: `${codigo ?? '-'} | ${nombre ?? 'ERROR'}` }))}
              value={bodega_instalacion_id?.split('-')?.[1] ?? 'Sin selección'}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
            />

            <TextareaInput
              labelText='* Descripción'
              name='descripcion'
              value={descripcion}
              handleValue={handleInputChange}
              size='col-12'
              sizeDesk='col-md-12'
              rows={2}
            />
          </div>

          <Button
            type="submit"
            text="Crear"
            color="primary"
            icon="Plus"
            onClick={(e) => handleFetch(e)}
          />
        </CustomForm>
      </div >
    </div >
  );
};

export default Create;
