import { useEffect, useState } from "react"
import moment from "moment/moment"


// Redux
import { useDispatch, useSelector } from "react-redux"
import { fireToaster } from "../../../redux/actions/toaster"
import { resultadosDevoluciones } from "../../../redux/actions/devoluciones"


// Components
import CustomLoader from "../../CustomLoader"
import Button from "../../Button"
import Table from "../../Table"
import CheckBoxInput from "../../CheckboxInput"
import FormInput from "../../FormInput"
import Modal from "../../Modal"


// Services
import { getAllDevoluciones } from "../../../services/devoluciones/getAll.service"
import { createDevolucionBitacora } from "../../../services/devoluciones/bitacora/create.service"


// Helpers
import { filtrosFormatter } from "../../AccordionFilter/Devoluciones/Devoluciones"
import { toasterErrorFormatter } from "../../../helpers/toasterErrorFormatter"
import { responseToasterContent } from "../../../helpers/toasterContent"
import { getAllBancos } from "services/devoluciones/bancos/getAll.service"


const CONSTANT = {
  reduxStateSelector: 'devoluciones',
  reduxSetResults: resultadosDevoluciones,

  form: {
    data: '', //str
    bitacora_id: null, // int
    estatus_id: null, // int
    fecha: moment().format('YYYY-MM-DD'), // date: YYYY-MM-DD
  },

  utilData: {
    bancos: []
  }
}


const fetchUtilData = async () => {
  return await Promise.all([
    getAllBancos()
      .then(({ data: bancos }) => ({ bancos: bancos.data }))
      .catch(err => {
        console.error(err)
        return { bancos: [] }
      })
  ])
    .then(arrRes => {
      let res = {}
      arrRes.forEach(d => Object.entries(d).forEach(([k, v]) => res = { ...res, [k]: v }))
      return res
    })
}


export const ModalBitacoraDevolucion = ({ data, isOpen, handleClose }) => {
  const [form, setForm] = useState(CONSTANT.form)
  const dispatch = useDispatch()
  const [isConsulting, setIsConsulting] = useState(false)
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const [tab, setTab] = useState('form')
  const [utilData, setUtilData] = useState(CONSTANT.utilData)

  useEffect(() => {
    if (!data) return

    setForm(prev => ({
      ...prev,
      data: data,
      bitacora_id: data?.id,
      estatus_id: data?.estado_id === 3 ? 1 : 0
    }))

    return () => {
      setForm(CONSTANT.form)
      setTab('form')
    }
  }, [data])


  useEffect(() => {
    fetchUtilData().then(res => setUtilData(res))
  }, [])


  const handleTab = (e) => {
    const { name } = e.target
    setTab(name)
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const handleFetch = async (e) => {
    e.preventDefault()

    const formatData = {
      data: form?.data,
      bitacora_id: Number(form.bitacora_id),
      estatus_id: Number(form.estatus_id) === 1 ? 3 : 2,
      fecha: moment(form.fecha).format('YYYY-MM-DD'),
    }

    setIsConsulting(true)

    await createDevolucionBitacora(formatData)
      .then(async res => {
        dispatch(fireToaster(responseToasterContent(res)))
        await fetchDevoluciones()
        handleClose()
      })
      .catch(err => {
        console.error(err);
        dispatch(fireToaster(responseToasterContent(err)))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  const fetchDevoluciones = async () => {
    await getAllDevoluciones(filtrosFormatter(filtros), showing_results)
      .then(res => {
        if (res.status >= 400) {
          dispatch(fireToaster(toasterErrorFormatter({ errors: res.data, response: res })))
          dispatch(CONSTANT.reduxSetResults([]))
          return
        }

        dispatch(CONSTANT.reduxSetResults(res.data))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  return (
    <Modal
      display={isOpen}
      handleValue={handleClose}
    >
      {isConsulting && <CustomLoader blocking={'partial'} />}

      {/* MARK: BANNER FORM | REG */}
      <div className="d-flex justify-content-between">
        <h4 className="my-0">Devolución #{data?.devolucion_id}</h4>

        <nav className="bitacora-devoluciones-tabs">
          [
          <button onClick={handleTab} name="form">Formulario</button>
          <span>|</span>
          <button onClick={handleTab} name="logs">Registros</button>
          ]
        </nav>
      </div>

      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center gap-2">
          {data?.numero_cuenta &&
            <>
              <div className="col-6 d-flex flex-column">
                <span >Número de cuenta:</span>

                <b>{data?.numero_cuenta ?? '-'}</b>
              </div>

              <div className="col-6 d-flex flex-column">
                <span>Banco: </span>

                <b>{utilData.bancos.find(({ id }) => Number(id) === Number(data?.banco))?.nombre ?? '-'}</b>
              </div>
            </>
          }
        </div>
      </div>

      <hr className="mt-1" />

      {/* MARK: FORM */}
      {tab === 'form' &&
        < section >
          <h5 className="text-center mb-1">Devolución | Formulario</h5>

          <form className="d-flex flex-column h-max">
            <div className="row">
              <FormInput
                labelText='* Fecha'
                name='fecha'
                type={'date'}
                value={form.fecha}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <CheckBoxInput
                name='estatus_id'
                value={form?.estatus_id ?? 0}
                labelText='Finalizado'
                handleValue={handleInputChange}
                classes='col-6 my-auto ms-auto'
              />

              <FormInput
                labelText='Comentario'
                placeholder='Escriba un comentario (opcional)'
                name='data'
                value={form?.data?.comentario ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />
            </div>
          </form>

          <div className="d-flex justify-content-between mt-2">
            <Button
              type="submit"
              text={'Cancelar'}
              color="danger"
              className={'w-fit'}
              icon={'X'}
              onClick={handleClose}
            />

            <Button
              type="submit"
              text={'Guardar'}
              color="success"
              className={'w-fit'}
              icon={'Check'}
              onClick={(e) => handleFetch(e)}
            />
          </div>
        </section>
      }

      {tab === 'logs' &&
        <section>
          <h5 className="text-center mb-1">Devolución | Tabla de registros</h5>

          <Table style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <tbody>
              {data?.registros?.length
                ? data?.registros.map(({ data, fecha, usuario, id, created_at }) => (
                  <tr key={`devolucion-bitacora-registros-data-${id}-${created_at}`}>
                    <td>
                      <div className="d-flex justify-content-between">
                        <p>Usuario: <b>{usuario?.nombre ?? 'Error'}</b></p>

                        <p>{fecha ?? 'Error'}</p>
                      </div>

                      <p>{data ?? 'Error'}</p>
                    </td>
                  </tr>
                ))
                : <tr><td className="text-center fw-bolder fs-5 text-lgray" colSpan={7}><i>Sin datos para mostrar...</i></td></tr>
              }
            </tbody>
          </Table>
        </section>
      }
    </Modal >
  )
}