import axios from 'axios'
import { useEffect, useState } from 'react'


// Redux
import { fireToaster } from '../../redux/actions/toaster'
import { useDispatch, useSelector } from 'react-redux'


// Components
import Accordion from '../../components/Accordion'
import AccordionDetailData from '../../components/AccordionDetailData'
import Button from '../../components/Button'
import Switch from '../../components/Switch'
import CustomLoader from '../../components/CustomLoader'


// Router Dom
import { useNavigate, useParams } from 'react-router-dom'


// Endpoints
import { getOne } from '../../endpoints/getOne'
import { historicoData } from '../../helpers/historicoData'
import { ModalHistorico } from '../../components/Historico/ModalHistorico'
import moment from 'moment'
import FullscreenImg from '../../components/FullscreenImg'
import { BASE_URL, PATH_URL } from '../../endpoints'
import ModalProcesar from '../../components/Modal/Garantias/ModalProcesar'
import ModalCompletar from '../../components/Modal/Garantias/ModalCompletar'
import { useCopyClipboard } from '../../hooks/useCopyClipboard'
import Icon from '../../components/Icon'


const CONSTANT = {
  queryUrlGetOne: getOne.garantias,
  permissionsPath: 'garantias',
  title: 'Garantías',
  redirectListado: '/garantias',
  redirectUpdate: (id, pedido_id, rut) => `/garantias/update/${id}?pedido_id=${pedido_id}&rut=${rut}`,
  urlDescargarPlanilla: (id) => `${BASE_URL}${PATH_URL}/garantias/planilla/${id}/pdf`,
  urlEnviarPlanilla: (id) => `${BASE_URL}${PATH_URL}/garantias/planilla/${id}/correo`,
  urlDescargarExcel: (id) => `${BASE_URL}${PATH_URL}/garantias/pirelli/${id}`,
  modelo: 'App\\Models\\Garantias',
}


const Detalle = () => {
  const [data, setData] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()
  const redirect = useNavigate()
  const [historico, setHistorico] = useState(null)
  const [modal, setModal] = useState({ id: null, display: false, data: null })
  const [procesarIsOpen, setProcesarIsOpen] = useState(false);
  const [completarIsOpen, setCompletarIsOpen] = useState(false);
  const { user } = useSelector(state => state.auth)
  const [copy] = useCopyClipboard()


  // EFECTO QUE CONSULTA LA INFORMACIÓN DEL REGISTRO
  useEffect(() => {
    handleGet(id)

    return () => {
      setData(null)
    }
  }, [id])


  // FUNCIÓN MANEJADORA PARA OBTENER EL REGISTRO
  const handleGet = (garantiaId) => {
    setData(null)
    axios(CONSTANT.queryUrlGetOne(garantiaId),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => setData(data))
      .catch(err => {
        console.error(err)
        const toasterContent = {
          title: 'Registro no encontrado',
          icon: 'warning'
        }

        dispatch(fireToaster(toasterContent))
        redirect(CONSTANT.redirectListado)
      })

    historicoData(Number(garantiaId), CONSTANT.modelo).then(data => setHistorico(data))
  }


  // FUNCION MANEJADORA DE LA DATA DEL MODAL
  const handleModal = (data) => {
    const { id, anterior, nuevo } = data

    setModal({
      display: true,
      id,
      data: {
        anterior,
        nuevo
      }
    })
  }


  const handleFiltrosPersistentes = (location) => {
    return `?filtros_persistentes=${location?.search?.split('filtros_persistentes=')?.[1] ?? '0'}`
  }


  return (
    <div className='row'>
      <ModalProcesar isOpen={procesarIsOpen} handleClose={() => setProcesarIsOpen(false)} handleGet={handleGet} />

      <ModalCompletar isOpen={completarIsOpen} handleClose={() => setCompletarIsOpen(false)} handleGet={handleGet} />

      <ModalHistorico modal={modal} fnHandleModal={handleModal} />

      <div className='col-12'>
        <div className='col mb-50'>
          <div className='d-flex gap-1'>
            <div className='col'>
              <Button text={CONSTANT.title} icon='ArrowLeft' isLink url={CONSTANT.redirectListado + handleFiltrosPersistentes(window.location)} />
            </div>
            {user.permisos[`${CONSTANT.permissionsPath}.editar`] &&
              <>
                {data?.estado?.estado?.toLocaleLowerCase() === 'ingresada' &&
                  <Button text='Procesar' icon='Settings' color='warning' onClick={() => setProcesarIsOpen(true)} />
                }

                {data?.estado?.estado?.toLocaleLowerCase() === 'procesada' &&
                  <Button text='Completar' icon='Check' color='success' onClick={() => setCompletarIsOpen(true)} />
                }

                {
                  data?.planilla && (
                    <>
                      <Button
                        isLink
                        text='Editar planilla - Respuesta'
                        icon='List'
                        color='warning'
                        url={`/garantias/planilla/update/${id}`}
                      />

                      <Button
                        isDownload
                        text='Descargar planilla - Respuesta'
                        icon='Download'
                        color='success'
                        url={CONSTANT.urlDescargarPlanilla(id)}
                      />

                      <Button
                        isDownload
                        text='Enviar planilla - Respuesta'
                        icon='Upload'
                        url={CONSTANT.urlEnviarPlanilla(id)}
                      />

                      <Button
                        isDownload
                        text='Descargar Excel - Pirelli'
                        icon='Download'
                        color='success'
                        url={CONSTANT.urlDescargarExcel(id)}
                      />
                    </>
                  )
                }

                <Button text='Editar' icon='Settings' color='warning' isLink url={CONSTANT.redirectUpdate(id, data?.pedido_id, data?.cliente_rut)} />
              </>
            }
          </div>
        </div>

        {data
          ? <>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <Accordion
                    title={<h3 className='m-0 w-100 text-center text-white'>Garantía</h3>}
                    classes='col-12 mb-2'
                    isOpen
                  >
                    <AccordionDetailData dataKey={'ID'} value={data?.id} />
                    <AccordionDetailData dataKey={'Estado'} value={<b>{data?.estado?.estado}</b>} />
                    <AccordionDetailData dataKey={'# Pedido'} value={data?.pedido_id} />
                    <AccordionDetailData dataKey={'Factura'} value={data?.numero_factura} />
                    <AccordionDetailData dataKey={'Creación'} value={moment(data?.fecha_creacion).format('DD-MM-YYYY')} />
                    <AccordionDetailData dataKey={'Envío'} value={data?.fecha_envio ? moment(data?.fecha_envio).format('DD-MM-YYYY') : '-'} />
                    <AccordionDetailData dataKey={'Comentarios'} value={data?.comentario ?? '-'} />
                    {/* <AccordionDetailData dataKey={'Observaciones'} value={data?.observacion ?? '-'} /> */}
                    <AccordionDetailData dataKey={'Condición observada 1'} value={data?.causa?.titulo ?? '-'} />
                    <AccordionDetailData dataKey={'Condición observada 2'} value={data?.causa_dos?.titulo ?? '-'} />
                    <AccordionDetailData dataKey={'Condición observada 3'} value={data?.causa_tres?.titulo ?? '-'} />
                    <AccordionDetailData dataKey={'Daño 1'} value={data?.daño?.daño ?? '-'} />
                    <AccordionDetailData dataKey={'Daño 2'} value={data?.daño_dos?.daño ?? '-'} />
                    <AccordionDetailData dataKey={'Daño 3'} value={data?.daño_tres?.daño ?? '-'} />
                    <AccordionDetailData dataKey={'Respuesta'} value={data?.respuesta?.titulo ?? '-'} last />
                    <AccordionDetailData dataKey={'Resultado'} value={data?.resultado?.resultado ?? '-'} />
                    <AccordionDetailData dataKey={'Conclusión'} value={data?.conclusion?.titulo ?? '-'} />
                    <AccordionDetailData dataKey={'Recomendación'} value={data?.recomendacion?.titulo ?? '-'} />
                  </Accordion>

                  <Accordion
                    title={<h3 className='m-0 w-100 text-center text-white'>Cliente</h3>}
                    classes='col-12 mb-2'
                    isOpen
                  >
                    <AccordionDetailData dataKey={'Nombre'} value={data?.cliente_nombre} />
                    <AccordionDetailData dataKey={'Rut'} value={data?.cliente_rut} />
                    <AccordionDetailData dataKey={'Email'} value={data?.cliente_email} />
                    <AccordionDetailData dataKey={'Contacto'} value={data?.cliente_telefono} last />
                  </Accordion>
                </div>
              </div>

              <div className='col-12 col-md-6'>
                <div className='row'>
                  <Accordion
                    title={<h3 className='m-0 w-100 text-center text-white'>Vehículo</h3>}
                    classes='col-12 mb-2'
                    isOpen
                  >
                    <AccordionDetailData
                      dataKey={'Patente'}
                      value={
                        <div className='d-flex align-items-center gap-1'>
                          <div className='d-flex' onClick={() => copy(data?.patente)}>
                            <Icon icon='Clipboard' size={20} className='cursor-pointer' />
                          </div>{data?.patente}
                        </div>
                      }
                    />
                    <AccordionDetailData dataKey={'Marca'} value={data?.vehiculo_marca ?? '-'} />
                    <AccordionDetailData dataKey={'Modelo'} value={data?.vehiculo_modelo ?? '-'} />
                    <AccordionDetailData dataKey={'Año'} value={data?.vehiculo_año ?? '-'} />
                    <AccordionDetailData dataKey={'Número de chasis'} value={data?.numero_chasis ?? '-'} last />
                  </Accordion>

                  <Accordion
                    title={<h3 className='m-0 w-100 text-center text-white'>Neumático</h3>}
                    classes='col-12 mb-2'
                    isOpen
                  >
                    <AccordionDetailData dataKey={'Nombre'} value={data?.producto_nombre} />
                    <AccordionDetailData dataKey={'SKU'} value={data?.producto_sku} />
                    <AccordionDetailData dataKey={'Posición'} value={data?.posicion?.posicion} />
                    <AccordionDetailData dataKey={'Recepción'} value={data?.fecha_recepcion ? moment(data?.fecha_recepcion).format('DD-MM-YYYY') : '-'} />
                    <AccordionDetailData dataKey={'Daño'} value={data?.daño?.daño ?? '-'} last />
                  </Accordion>

                  <Accordion
                    title={<h3 className='m-0 w-100 text-center text-white'>Evidencias</h3>}
                    classes='col-12 mb-2'
                  >
                    {
                      data?.evidencias.map((ev, i) => (
                        <AccordionDetailData
                          key={`evidencias-${ev.id}`}
                          dataKey={i + 1}
                          value={
                            <FullscreenImg
                              imgSrc={`${BASE_URL}storage/${ev.ruta}`}
                              alt={ev.nombre}
                            />
                          }
                          classes='flex-grow-1'
                          last={i + 1 === data?.evidencias.length}
                        />
                      ))
                    }
                  </Accordion>
                </div>
              </div>
            </div>

            {/* <TablaDetalle historicoData={historico} fnHandleModal={handleModal} /> */}
          </>

          : <CustomLoader />
        }
      </div>
    </div>
  )
}

export default Detalle