import { useEffect, useState } from "react";
import axios from "axios";

// Redux
import { useDispatch } from "react-redux";
import { fireToaster } from "../../redux/actions/toaster";


//	Components
import FormInput from "../../components/FormInput";
import CustomLoader from "../../components/CustomLoader";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import Switch from "../../components/Switch";


// React-router-dom
import { useNavigate, useParams } from "react-router-dom";


// Endpoints
import { getOne } from "../../endpoints/getOne";
import TextareaInput from "../../components/TextareaInput";
import { BASE_URL, PATH_URL } from "../../endpoints";
import SelectFilter from "../../components/SelectFilter";
import { getAllBodegas } from "services/bodegas/getAll.service";
import { idExtractor } from "helpers/idExtractor";


const CONSTANT = {
  redirectUrl: '/generales/metodos-entrega',
  componentTitle: 'Editar método de entrega',
  title: 'Métodos de entrega',

  nullObject: { id: '*NULL*', nombre: 'Error' },
  unselectedObject: { id: '-', nombre: 'Sin selección' },

  queryUrl: getOne.metodos_entrega,

  fetchModosEntrega: BASE_URL + PATH_URL + `/erp/modos-despachos/obtener`,

  initialformState: {
    nombre: '',
    descripcion: '',
    bodega_instalacion_id: '',
    cod_erp: '',
    estatus: true
  },

  selectFilter: {
    modosDespacho: [],
    bodegas: [],
  }
}


const fetchSelectFiltersData = async () => {

  return await Promise.all([
    axios(CONSTANT.fetchModosEntrega,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => ({ modosDespacho: [...data?.data] ?? [] }))
      .catch(err => {
        console.error(err)
        return ({ modosDespacho: [] })
      }),

    getAllBodegas()
      .then(({ data }) => ({ bodegas: [CONSTANT.unselectedObject, ...data] }))
      .catch(err => {
        console.error(err)
        return ({ bodegas: [] })
      }),
  ])
    .then(res => {
      let r = {}
      res.forEach((x) => {
        Object.entries(x).forEach(([k, v]) => r = { ...r, [k]: [...v] })
      })
      return r
    })
}


const Update = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fetchData, setFetchData] = useState(null)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const { id } = useParams()


  // FUNCIÓN MANEJADORA QUE ACTUALIZA EL ESTADO DE 'FetchData'
  const handleInputChange = (e) => {
    const { target } = e

    setFetchData({
      ...fetchData,
      [target.name]: target.value,
    })
  }


  // EFFECTO QUE SETEA LA DATA DE LOS SELECTFILTER
  useEffect(() => {
    fetchSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFECTO QUE CONSULTA LA DATA DEL REGISTRO Y ACTUALIZA EL ESTADO 'FetchData'
  useEffect(() => {
    axios(CONSTANT.queryUrl(id),
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
      })
      .then(({ data }) => {
        const seletedModoDespacho = selectFilter.modosDespacho.find(({ cod_erp }) => cod_erp === data?.cod_erp)
        const seletedBodega = selectFilter.bodegas.find(({ id }) => id === data?.bodega_instalacion_id)
        
        setFetchData({
          ...data,
          cod_erp: `${seletedModoDespacho?.cod_erp ?? '*NULL*'}-${seletedModoDespacho?.nombre ?? 'Error'}`,
          bodega_instalacion_id: `${seletedBodega?.id ?? '*NULL*'}-${seletedBodega?.codigo ?? '-'} | ${seletedBodega?.nombre ?? 'Error'}`,
        })
      })
      .catch(err => console.error(err))

    return () => {
      setFetchData(null)
    }
  }, [id, selectFilter.modosDespacho, selectFilter.bodegas])


  const handleFetch = async (e) => {
    e.preventDefault()

    let data = {
      nombre: fetchData?.nombre,
      descripcion: fetchData?.descripcion,
      bodega_instalacion_id: idExtractor(fetchData?.bodega_instalacion_id),
      cod_erp: fetchData?.cod_erp?.split('-')?.[0],
      estatus: Number(fetchData?.estatus) ? true : false
    }

    // for (const [k, v] of data) {
    //   console.log(k, v);
    // }

    axios(CONSTANT.queryUrl(id), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json',
        '_method': 'PUT'
      },
      data
    })
      .then(({ data }) => {
        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: ${data.nombre} actualizado con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }

  return (
    <div className="row">
      <div className="col col-md-6 mx-auto">
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>
        {fetchData?.id && selectFilter.modosDespacho.length && selectFilter.bodegas.length
          ? <CustomForm size="" title={CONSTANT.componentTitle}>
            <div className="row">
              <Switch
                labelText='Estatus'
                name='estatus'
                value={fetchData?.estatus}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <SelectFilter
                labelText='Modo despacho'
                name='cod_erp'
                optionObj={selectFilter.modosDespacho.map(({ cod_erp, nombre }) => ({ id: cod_erp ?? '*NULL*', name: `${nombre}` ?? 'ERROR' }))}
                value={fetchData?.cod_erp?.split('-')?.[1] ?? 'Sin selección'}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <FormInput
                labelText='* Nombre'
                name='nombre'
                value={fetchData?.nombre ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-6'
              />

              <SelectFilter
                labelText='Bodega instalación'
                name='bodega_instalacion_id'
                optionObj={selectFilter.bodegas.map(({ id, codigo, nombre }) => ({ id: id ?? '*NULL*', name: `${codigo ?? '-'} | ${nombre ?? 'ERROR'}` }))}
                value={fetchData?.bodega_instalacion_id?.split('-')?.[1] ?? 'Sin selección'}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
              />

              <TextareaInput
                labelText='* Descripción'
                name='descripcion'
                value={fetchData?.descripcion ?? ''}
                handleValue={handleInputChange}
                size='col-12'
                sizeDesk='col-md-12'
                rows={2}
              />
            </div>

            <Button
              type="submit"
              text="Actualizar"
              color="primary"
              icon="Circle"
              onClick={handleFetch}
            />
          </CustomForm>
          : <CustomLoader />
        }
      </div>
    </div>

  );
};

export default Update;
