import {
   FILTER_BUSQUEDA,
   FILTER_CLEAR,
   FILTROS_PERSISTENTES_GARANTIAS_CONCLUSIONES,
   RESULTADOS_GARANTIAS_CONCLUSIONES,
} from "../redux-types";

export const busquedaFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })

export const resultadosGarantiasConclusiones = (data) => ({ type: RESULTADOS_GARANTIAS_CONCLUSIONES, payload: data })

export const filtrosPersistentesGarantiasConclusiones = (data) => ({ type: FILTROS_PERSISTENTES_GARANTIAS_CONCLUSIONES, payload: data })

export const clearFilterGarantiasConclusiones = () => ({ type: FILTER_CLEAR });
