import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_DIVISA, FILTER_ADD_ESTATUS_PAGO, FILTER_ADD_ESTATUS_PEDIDO, FILTER_ADD_FRONTEND, FILTER_ADD_METODO_ENTREGA, FILTER_ADD_METODO_PAGO, FILTER_ADD_PUNTO_ENTREGA, FILTER_ADD_TIPO_FACTURACION, FILTER_ADD_USUARIO, FILTER_BUSQUEDA, FILTER_FECHA_AGENDA, FILTER_FECHA_FINAL, FILTER_FECHA_INICIO, FILTER_NOMBRE_CLIENTE, FILTER_PAIS, FILTER_PEDIDO_ID } from '../../redux/redux-types'
import { agendaDesdeFilter, agendaFechaFilter, agendaHastaFilter, busquedaUsuarioFilter, clearFilterPedidos, divisaFilter, estadoPagoFilter, estatusPedidoFilter, filtrosPersistentesPedidos, frontendFilter, metodoEntregaFilter, metodoPagoFilter, paisFilter, pedidoIdFilter, puntoEntregaFilter, resultadosPedidos, tipoFacturacionFilter, usuarioFilter } from '../../redux/actions/pedidos'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import { queryParamsFormatter } from '../../helpers/queryParamsFormatter'
import SelectFilter from '../SelectFilter'
import { lastPosition } from '../../helpers/lastPosition'
import FiltroAplicado from './FiltroAplicado'
import { idExtractor } from '../../helpers/idExtractor'
import CustomLoader from '../CustomLoader'
import { persistentFilters } from '../../helpers/pesistentFilters'


// Services
import { searchPaises } from 'services/paises/getAll.service'
import { getAllFrontends } from 'services/frontend/getAll.service'
import { searchPuntosEntrega } from 'services/puntosEntrega/getAll.service'
import { getAllMetodosEntrega } from 'services/metodosEntrega/getAll.service'
import { getAllMetodosPago } from 'services/metodosPago/getAll.service'
import { getAllDivisas } from 'services/divisas/getAll.service'
import { getAllTiposFacturacion } from 'services/tiposFacturacion/getAll.service'
import { getAllEstatusPedido } from 'services/estatusPedido/getAll.service'


const CONSTANT = {
  queryUrlGetAll: getAll.pedidos + '/listar',

  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },

  redirectUrl: '/pedidos',
  title: 'Búsqueda de pedidos',

  reduxClearFilters: clearFilterPedidos,
  reduxSetResults: resultadosPedidos,

  selectFilterInitialState: {
    paises: [],
    usuarios: [],
    puntos_entrega: [],
    metodos_entrega: [],
    metodos_pago: [],
    divisas: [],
    frontendId: [],
    tipos_facturacion: [],
    estatus_pedido: [],
    estadoPago: [{ id: 0, nombre: 'No pagado' }, { id: 1, nombre: 'Pagado' }]
  },

  queryUrl: (results) => CONSTANT.queryUrlGetAll + `?limit=${results}`
}

export const dataFormatter = (obj) => {
  let data = {}
  Object.entries(obj).forEach(([k, v]) => {
    if (['usuario_id', 'puntoentrega_id', 'metodo_entrega_id', 'metodo_pago_id', 'divisa_id', 'tipo_facturacion_id', 'estatus_pedido_id', 'id', 'estado_pago', 'frontend_id'].includes(k) && v.length) {
      return data = {
        ...data,
        filtros: {
          ...data.filtros,
          [k]: k === 'id'
            ? [Number(v)]
            : v.map(str => idExtractor(str))
        }
      }
    }
  })
  return data
}


const fetchSelectFiltersData = async () => {
  return await Promise.all([
    searchPaises()
      .then(({ data }) => ({ paises: [CONSTANT.unselectedObject, ...data] }))
      .catch(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      }),

    getAllFrontends()
      .then(({ data }) => ({ frontendId: [CONSTANT.unselectedObject, ...data] }))
      .catch(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      }),

    searchPuntosEntrega()
      .then(({ data }) => ({ puntos_entrega: [CONSTANT.unselectedObject, ...data?.data] }))
      .catch(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      }),

    getAllMetodosEntrega()
      .then(({ data }) => ({ metodos_entrega: [CONSTANT.unselectedObject, ...data?.data] }))
      .catch(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      }),

    getAllMetodosPago()
      .then(({ data }) => ({ metodos_pago: [CONSTANT.unselectedObject, ...data?.data] }))
      .catch(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      }),

    getAllDivisas()
      .then(({ data }) => ({ divisas: [CONSTANT.unselectedObject, ...data?.data] }))
      .catch(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      }),

    getAllTiposFacturacion()
      .then(({ data }) => ({ tipos_facturacion: [CONSTANT.unselectedObject, ...data?.data] }))
      .catch(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      }),

    getAllEstatusPedido()
      .then(({ data }) => ({ estatus_pedido: [CONSTANT.unselectedObject, ...data] }))
      .catch(err => {
        console.error(err)
        return [CONSTANT.nullObject]
      }),
  ])

    .then(arrRes => {
      let res = {}
      arrRes.forEach(d => Object.entries(d).forEach(([k, v]) => res = { ...res, [k]: v }))
      return res
    })


}


const PedidosFiltros = () => {
  const { filtros } = useSelector(state => state.pedidos)
  const { showing_results } = useSelector(state => state.listedResults)
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const {
    busqueda_usuario,
    id,
    pais_id,
    desde,
    hasta,
    fecha,
    usuario_id,
    puntoentrega_id,
    metodo_entrega_id,
    metodo_pago_id,
    divisa_id,
    tipo_facturacion_id,
    estatus_pedido_id,
    estado_pago,
    frontend_id
  } = filtros

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    // const filtrosPersistentesObj = persistentFilters(window.location, filtros).obj
    // let url = CONSTANT.queryUrl(showing_results)
    // let config = {
    //   headers: {
    //     'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
    //   },
    //   method: 'POST',
    // }

    // if (filtrosPersistentesObj) {
    //   dispatch(filtrosPersistentesPedidos(filtrosPersistentesObj))
    //   url = CONSTANT.queryUrl(showing_results) + queryParamsFormatter(
    //     {
    //       busqueda_usuario: filtrosPersistentesObj.busqueda_usuario,
    //       pais_id: filtrosPersistentesObj.pais_id,
    //       desde: filtrosPersistentesObj.desde,
    //       hasta: filtrosPersistentesObj.hasta,
    //       fecha: filtrosPersistentesObj.fecha
    //     }
    //   )
    //   config = { ...config, data: dataFormatter(filtrosPersistentesObj) }
    // }

    // axios(url, config)
    //   .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
    //   .catch(err => {
    //     console.error(err)
    //     dispatch(CONSTANT.reduxSetResults([]))
    //   })
  }, [dispatch, showing_results])


  // EFECTO QUE SETEA LOS VALORES DE LOS SELECTFILTERS Y FECHA DESDE
  useEffect(() => {
    fetchSelectFiltersData().then(data => setSelectFilter(selectFilter => ({ ...selectFilter, ...data })))
  }, [dispatch])


  // EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
  useEffect(() => {
    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    let config = {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
      data: {}
    }


    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      return
      // axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}`, config)
      //   .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      //   .catch(err => {
      //     console.error(err)
      //     dispatch(CONSTANT.reduxSetResults([]))
      //   })
      //   .finally(() => {
      //     setIsConsulting(false)
      //   })
    }

    config = { ...config, data: dataFormatter(filtros) }

    setIsConsulting(true)

    await axios(CONSTANT.queryUrlGetAll + `?limit=${showing_results}` + queryParamsFormatter({ busqueda_usuario, pais_id, desde, hasta, fecha }), config)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
        dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
        window.history.pushState({}, '', persistentFilters(window.location, filtros).urlWithFilters)
      })
      .catch(err => {
        console.error(err)

        const { errores } = err.response.data
        let detalles = []

        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
            `,
          html: `
            <b> Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
            `,
          icon: 'error'
        }

        dispatch(fireToaster(toasterContent))
        CONSTANT.reduxSetResults([])
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }


  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value } = e.target

    switch (filter) {
      case FILTER_ADD_ESTATUS_PAGO:
        dispatch(estadoPagoFilter(value))
        break;

      case FILTER_NOMBRE_CLIENTE:
        dispatch(busquedaUsuarioFilter(value))
        break;

      case FILTER_PAIS:
        dispatch(paisFilter(value))
        break;

      case FILTER_FECHA_INICIO:
        dispatch(agendaDesdeFilter(value))
        break;

      case FILTER_FECHA_FINAL:
        dispatch(agendaHastaFilter(value))
        break;

      case FILTER_FECHA_AGENDA:
        dispatch(agendaFechaFilter(value))
        break;

      case FILTER_ADD_USUARIO:
        dispatch(usuarioFilter(value))
        break;

      case FILTER_ADD_PUNTO_ENTREGA:
        dispatch(puntoEntregaFilter(value))
        break;

      case FILTER_ADD_METODO_ENTREGA:
        dispatch(metodoEntregaFilter(value))
        break;

      case FILTER_ADD_METODO_PAGO:
        dispatch(metodoPagoFilter(value))
        break;

      case FILTER_ADD_DIVISA:
        dispatch(divisaFilter(value))
        break;

      case FILTER_ADD_TIPO_FACTURACION:
        dispatch(tipoFacturacionFilter(value))
        break;

      case FILTER_ADD_ESTATUS_PEDIDO:
        dispatch(estatusPedidoFilter(value))
        break;

      case FILTER_PEDIDO_ID:
        dispatch(pedidoIdFilter(value))
        break;

      case FILTER_ADD_FRONTEND:
        dispatch(frontendFilter(value));
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-2 shadow-none bg-transparent">
      {isConsulting && <CustomLoader blocking='full' />}

      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
      >
        <>
          <FormInput
            labelText='Búsqueda de usuario'
            placeholder='nombre, RUT, fono, email...'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_NOMBRE_CLIENTE)}
            value={busqueda_usuario}
          />

          <FormInput
            type='number'
            placeholder='Número de pedido...'
            labelText='# Pedido'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_PEDIDO_ID)}
            value={id}
          />

          <SelectFilter
            labelText="Frontend"
            optionObj={selectFilter?.frontendId?.map(({ id, nombre }) => ({ id, name: nombre }))}
            size="col-12"
            sizeDesk={"col-md-3"}
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_FRONTEND)}
            value={lastPosition(frontend_id)}
            disabled={!selectFilter?.frontendId?.length}
          />

          <FormInput
            type='date'
            labelText='Desde'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_INICIO)}
            value={desde}
          />

          <FormInput
            type='date'
            labelText='Hasta'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_FECHA_FINAL)}
            value={hasta}
          />

          <SelectFilter
            labelText='País'
            size='col-12'
            sizeDesk='col-md-3'
            handleValue={(e) => handleAddFilter(e, FILTER_PAIS)}
            optionObj={selectFilter.paises.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={pais_id?.split('-')[1] ?? 'Seleccione un país'}
            disabled={!selectFilter?.paises?.length}
          />

          <SelectFilter
            labelText='Punto de entrega'
            size='col-12'
            sizeDesk='col-md-6'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_PUNTO_ENTREGA)}
            optionObj={selectFilter?.puntos_entrega.map(({ id, direccion, tipo_destino, comuna }) => ({ id, name: `${tipo_destino?.nombre ?? 'N/A'} | ${direccion ?? 'N/A'} | ${comuna?.region?.nombre ?? 'N/A'}` }))}
            value={lastPosition(puntoentrega_id)}
            disabled={!selectFilter?.puntos_entrega?.length}
          />

          <SelectFilter
            labelText='Método de entrega'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_METODO_ENTREGA)}
            optionObj={selectFilter.metodos_entrega.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(metodo_entrega_id)}
            disabled={!selectFilter?.metodos_entrega?.length}
          />

          <SelectFilter
            labelText='Método de pago'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_METODO_PAGO)}
            optionObj={selectFilter.metodos_pago.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(metodo_pago_id)}
            disabled={!selectFilter?.metodos_pago?.length}
          />

          <SelectFilter
            labelText='Divisa'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_DIVISA)}
            optionObj={selectFilter.divisas.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(divisa_id)}
            disabled={!selectFilter?.divisas?.length}
          />

          <SelectFilter
            labelText='Tipo de facturación'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_TIPO_FACTURACION)}
            optionObj={selectFilter.tipos_facturacion.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(tipo_facturacion_id)}
            disabled={!selectFilter?.tipos_facturacion?.length}
          />

          <SelectFilter
            labelText='Estatus de pedido'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_ESTATUS_PEDIDO)}
            optionObj={selectFilter.estatus_pedido.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(estatus_pedido_id)}
            disabled={!selectFilter?.estatus_pedido?.length}
          />

          <SelectFilter
            labelText='Estato de pago'
            size='col-12'
            sizeDesk='col-md-2'
            handleValue={(e) => handleAddFilter(e, FILTER_ADD_ESTATUS_PAGO)}
            optionObj={selectFilter.estadoPago.map(({ id, nombre }) => ({ id, name: nombre }))}
            value={lastPosition(estado_pago)}
            disabled={!selectFilter?.estadoPago?.length}
          />
        </>

        {/* FILTROS APLICADOS - ADICIONALES */}
        <p className='mb-25 text-black'>Filtros aplicados</p>
        <div className='row'>
          <FiltroAplicado array={frontend_id} func={frontendFilter} title="Frontend" />
          {/* <FiltroAplicado array={usuario_id} func={usuarioFilter} title={'Usuarios'} /> */}
          <FiltroAplicado array={puntoentrega_id} func={puntoEntregaFilter} title={'Puntos de entrega'} />
          <FiltroAplicado array={metodo_entrega_id} func={metodoEntregaFilter} title={'Métodos de entrega'} />
          <FiltroAplicado array={metodo_pago_id} func={metodoPagoFilter} title={'Métodos de pago'} />
          <FiltroAplicado array={divisa_id} func={divisaFilter} title={'Divisas'} />
          <FiltroAplicado array={tipo_facturacion_id} func={tipoFacturacionFilter} title={'Tipos de facturación'} />
          <FiltroAplicado array={estatus_pedido_id} func={estatusPedidoFilter} title={'Estatus de pedido'} />
          <FiltroAplicado array={estado_pago} func={estadoPagoFilter} title={'Estato de pago'} />
        </div>

        <hr className='my-1' />
      </ListadoHeader>

      {/* <CustomLoader /> */}

    </div>
  )
}

export default PedidosFiltros