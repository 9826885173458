import { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '../../Modal';
import { getAll } from '../../../endpoints/getAll';
import SelectFilter from '../../SelectFilter';
import FormInput from '../../FormInput';
import Button from '../../Button';
import { useDispatch } from 'react-redux';
import { fireToaster } from '../../../redux/actions/toaster';
import { idExtractor } from '../../../helpers/idExtractor';
import { BASE_URL, PATH_URL } from '../../../endpoints';
import { useParams } from 'react-router-dom';
import { getAllGarantiasCausas } from 'services/garantias/causas/getAll.service';
import { getAllGarantiasDanos } from 'services/garantias/danos/getAll.service';
import { searchUsuarios } from 'services/usuarios/getAll.service';


const CONSTANT = {
  title: 'Garantía',
  componentTitle: 'Procesar garantía',
  redirectUrl: '/garantias',
  queryUrl: (id) => `${BASE_URL}${PATH_URL}/garantias/procesar/${id}`,
  fetchDaños: getAll.garantia_daños,
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  initialFormState: {
    daño_id: '',
    fecha_recepcion: '',
    fecha_envio: '',
    // observacion: '',
    causa_id: '',
    causa_dos_id: '',
    causa_tres_id: '',
    evaluador_id: '',
  },
  selectFilterInitialState: {
    daños: [],
    causas: [],
    evaluadores: []
  }
}


const handleSelectFiltersData = async () => {
  const daños = await getAllGarantiasDanos()
    .then(({ data }) => [CONSTANT.unselectedObject, ...data.data])
    .catch(err => {
      console.error(err)
      return []
    })

  const causas = await getAllGarantiasCausas()
    .then(({ data }) => [CONSTANT.unselectedObject, ...data.data])
    .catch(err => {
      console.error(err)
      return []
    })

  const evaluadores = await searchUsuarios({ ejecutivo: 1 })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data.data])
    .catch(err => {
      console.error(err)
      return []
    })

  return { daños, causas, evaluadores }
}


const ModalProcesar = ({ isOpen, handleClose, handleGet }) => {
  const dispatch = useDispatch()

  const { id: garantiaId } = useParams()

  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [form, setForm] = useState(CONSTANT.initialFormState);


  // EFFECTO QUE DISPARA EL MANEJADOR DE DATA PARA SELECTFILTERS
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // MANEJADOR DE LOS INPUTS
  const handleInputChange = (e) => {
    const { value, name } = e.target

    setForm({
      ...form,
      [name]: value
    })
  }

  const handleFetch = (e) => {
    e.preventDefault()

    if (!form.daño_id || form.daño_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'El daño es necesario', icon: 'info' }))
    if (!form.causa_id || form.causa_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'La causa es necesario', icon: 'info' }))
    if (!form.evaluador_id || form.evaluador_id.includes('*NULL*')) return dispatch(fireToaster({ title: 'El evaluador es necesario', icon: 'info' }))
    if (!form.fecha_recepcion) return dispatch(fireToaster({ title: 'La fecha de recepción es obligatoria', icon: 'info' }))
    if (!form.fecha_envio) return dispatch(fireToaster({ title: 'La fecha de envío es obligatoria', icon: 'info' }))

    let data = {
      daño_id: idExtractor(form.daño_id),
      causa_id: idExtractor(form.causa_id),
      causa_dos_id: idExtractor(form.causa_dos_id),
      causa_tres_id: idExtractor(form.causa_tres_id),
      evaluador_id: idExtractor(form.daño_id),
      fecha_recepcion: form.fecha_recepcion,
      fecha_envio: form.fecha_envio,
      // observacion: form.observacion,
    }

    axios(CONSTANT.queryUrl(garantiaId), {
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
        'Content-Type': 'application/json'
      },
      data
    })
      .then(res => {

        const toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: #${garantiaId} actualizada con éxito`,
          icon: 'success'
        }
        dispatch(fireToaster(toasterContent))
        handleGet(garantiaId)
        handleClose()
      })
      .catch(err => {
        console.log(err);
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
          html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  return (
    <Modal display={isOpen} handleValue={handleClose}>
      <h3 className='text-center'>Formulario procesamiento de garantía</h3>

      <form className='row' onSubmit={handleFetch}>
        <SelectFilter
          labelText="* Evaluador"
          name="evaluador_id"
          value={form?.evaluador_id?.split('-')[1] ?? 'Seleccione'}
          handleValue={handleInputChange}
          optionObj={selectFilter?.evaluadores?.map(({ nombre, id }) => ({ name: nombre, id }))}
          size="col-12"
          sizeDesk='col-12'
        />

        <SelectFilter
          labelText="* Daño"
          name="daño_id"
          value={form?.daño_id?.split('-')[1] ?? 'Seleccione'}
          handleValue={handleInputChange}
          optionObj={selectFilter?.daños?.map(({ daño, nombre, id }) => ({ name: nombre ?? daño, id }))}
          size="col-12"
          sizeDesk='col-12'
        />

        <SelectFilter
          labelText="* Condición observada 1"
          name="causa_id"
          value={form?.causa_id?.split('-')[1] ?? 'Seleccione'}
          handleValue={handleInputChange}
          optionObj={selectFilter?.causas?.map(({ nombre, titulo, id }) => ({ name: titulo ?? nombre, id }))}
          size="col-12"
          sizeDesk='col-12'
        />

        <SelectFilter
          labelText="* Condición observada 2"
          name="causa_dos_id"
          value={form?.causa_dos_id?.split('-')[1] ?? 'Seleccione'}
          handleValue={handleInputChange}
          optionObj={selectFilter?.causas?.map(({ nombre, titulo, id }) => ({ name: titulo ?? nombre, id }))}
          size="col-12"
          sizeDesk='col-12'
        />

        <SelectFilter
          labelText="* Condición observada 3"
          name="causa_tres_id"
          value={form?.causa_tres_id?.split('-')[1] ?? 'Seleccione'}
          handleValue={handleInputChange}
          optionObj={selectFilter?.causas?.map(({ nombre, titulo, id }) => ({ name: titulo ?? nombre, id }))}
          size="col-12"
          sizeDesk='col-12'
        />

        <FormInput
          labelText="* Fecha de recepción de neumático"
          name='fecha_recepcion'
          type='date'
          handleValue={handleInputChange}
          value={form?.fecha_recepcion}
          size="col-12"
          sizeDesk="col-md-6"
        />

        <FormInput
          labelText="* Fecha de envío de neumático"
          name='fecha_envio'
          type='date'
          handleValue={handleInputChange}
          value={form?.fecha_envio}
          size="col-12"
          sizeDesk="col-md-6"
        />

        {/* <TextareaInput
          labelText='Observaciones'
          name='observacion'
          placeholder='Escriba sus observaciones...'
          value={form.observacion}
          handleValue={handleInputChange}
        /> */}

        <div className='d-flex justify-content-end'>
          <Button type='submit' text='Actualizar' />
        </div>
      </form>
    </Modal>
  );
}



export default ModalProcesar;