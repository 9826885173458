import React, { useEffect, useState } from "react";
import axios from "axios";
import validator from "validator";

// Redux
import { fireToaster } from "../../redux/actions/toaster";
import { useDispatch, useSelector } from "react-redux";


//	Components
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import CustomForm from "../../components/CustomForm";
import SelectFilter from "../../components/SelectFilter";
import TextareaInput from "../../components/TextareaInput";
import ProductosFiltros from "../../components/AccordionFilter/Productos";
import CustomLoader from "../../components/CustomLoader";
import CheckBoxInput from "../../components/CheckboxInput";
import { NoResults } from "../../components/Tables/Misc/NoResults";
import Table from "../../components/Table";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";


// React-router
import { useNavigate } from "react-router-dom";


// Endpoints
import { create } from "../../endpoints/create";
import { getAll } from "../../endpoints/getAll";
import { BASE_URL } from "../../endpoints";


// Layouts
import AccordionTable from "../../layout/AccordionTable";
import { PrecioFormatter } from "../../helpers/precioFormatter";
import { renderRecargoInstalacion } from "../../helpers/renderRecargoInstalacion";
import { searchPaises } from "../../services/paises/getAll.service";


const idExtractor = (str) => {
  if (typeof str !== 'string') {
    return null
  }
  const [id] = str.split('-')
  if (!id || typeof Number(id) !== 'number') {
    return null
  }
  return Number(id)
}


const CONSTANT = {
  redirectUrl: '/cotizaciones',
  componentTitle: 'Generar cotización',
  nullObject: { id: '*NULL*', nombre: '- Error -' },
  unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
  title: 'Cotizaciones',

  queryUrl: create.cotizaciones,

  queryUrlUsuarios: getAll.usuarios + '/listar',
  queryUrlRoles: getAll.roles,
  queryUrlPuntosEntrega: getAll.puntos_entrega + '/listar?estatus=1',
  queryUrlTiposFacturacion: getAll.tipos_facturacion,
  queryUrlTiposAtencion: getAll.tipos_atencion,
  queryUrlMetodosPago: getAll.metodos_pago,
  queryUrlFormasPago: getAll.formas_pago,
  queryUrlDivisas: getAll.divisas,
  fetchEjecutivos: getAll.usuarios + '/listar?ejecutivo=1',
  fetchFrontendId: getAll.frontend_id,

  fetchAction: {
    guardar_enviar_con_totales: 'guardar y enviar c t',
    guardar_enviar_sin_totales: 'guardar y enviar s t',
    guardar: 'guardar'
  },

  initialformState: {
    // nullish
    forma_pago_id: undefined,
    pais_cliente_id: undefined,
    usuario_id: undefined,
    metodo_pago_id: undefined, //Tiene valor por defecto
    divisa_id: undefined, //Tiene valor por defecto
    nombre_cliente: '',
    apellido_cliente: '',
    email_cliente: '',
    telefono_cliente: '',
    rut_empresa: '',
    direccion_entrega: null,
    observaciones: '',
    rut_cliente: '',
    frontend_id: '', //Tiene valor por defecto
    razon_social: '',
    tipo_cliente_id: '',
    referencia_externa: '',

    // required
    tipo_facturacion_id: undefined, //Tiene valor por defecto
    tipo_atencion_id: undefined,
    puntoentrega_id: undefined,
    dias_vigencia_cotizacion: 15,
    estatus: true
  },

  selectFilter: {
    puntosEntrega: [],
    tiposAtencion: [],
    ejecutivos: [],
    formasPago: [],
    tiposFacturacion: [],
    metodosPago: [],
    divisas: [],
    frontendId: [],
    roles: [],
    paises: []
  }
}


const Precio = (v) => new PrecioFormatter(v).producto()
const PrecioRaw = (v) => new PrecioFormatter(v).producto(true)


const validateOferta = (producto) => {
  const criterio = producto?.neu_oferta_producto?.[0]?.neu_oferta?.neu_criterio_comparacion?.simbolo ?? null
  const { cantidad } = producto
  const limite = Number(producto?.neu_oferta_producto?.[0]?.neu_oferta?.cantidad_limite)

  switch (criterio) {
    case '>':
      return cantidad > limite

    case '>=':
      return cantidad >= limite

    case '=':
      return cantidad === limite

    case '<=':
      return cantidad <= limite

    case '<':
      return cantidad < limite

    default:
      return false
  }
}


const renderInstalacionTotal = (isTarifaPlana, instalacionHasta, arrlineasProductos, producto) => {
  const ApplyInst = producto?.neu_punto_entrega_producto?.[0]?.precio_instalacion !== null
  const hasInstPermanente = arrlineasProductos?.find(({ neu_punto_entrega_producto }) => neu_punto_entrega_producto?.[0]?.instalacion_permanente) ?? 0
  const subtotalLineasProductos = arrlineasProductos?.reduce((prev, current) => prev += Number(current.precio_unitario) * Number(current.cantidad), 0) ?? 0

  const prodInstalacion = Number(producto?.neu_punto_entrega_producto?.[0]?.precio_instalacion) * producto.cantidad

  return ApplyInst
    ? (isTarifaPlana)
      ? (hasInstPermanente)
        ? 'Tarifa plana'
        : instalacionHasta
          ? (subtotalLineasProductos >= instalacionHasta)
            ? 'Gratis'
            : 'Tarifa plana'
          : 'Tarifa plana'
      : (hasInstPermanente)
        ? renderRecargoInstalacion(prodInstalacion)
        : instalacionHasta
          ? (subtotalLineasProductos >= instalacionHasta)
            ? 'Gratis'
            : renderRecargoInstalacion(prodInstalacion)
          : renderRecargoInstalacion(prodInstalacion)
    : 'No aplica'
}


const renderSubtotalProducto = (arrProd, isTarifaPlana, instalacionHasta, prod) => {
  const hasInstPermanente = arrProd?.find(({ neu_punto_entrega_producto }) => neu_punto_entrega_producto?.[0]?.instalacion_permanente) ?? 0
  const totalQty = arrProd?.reduce((prev, current) => prev += current.cantidad, 0)
  const higherInst = Number(arrProd?.sort((a, b) => Number(b?.neu_punto_entrega_producto?.[0]?.precio_instalacion) - Number(a?.neu_punto_entrega_producto?.[0]?.precio_instalacion))?.[0]?.neu_punto_entrega_producto?.[0]?.precio_instalacion) ?? 0
  const subtotalLineasProductos = arrProd?.reduce((prev, current) => prev += Number(current.precio_unitario) * Number(current.cantidad), 0) ?? 0

  return (isTarifaPlana)
    ? (hasInstPermanente)
      ? Number(higherInst / totalQty)
      : (subtotalLineasProductos >= instalacionHasta)
        ? 0
        : Number(higherInst / totalQty)
    : (hasInstPermanente)
      ? Number(prod?.neu_punto_entrega_producto?.[0]?.precio_instalacion)
      : (subtotalLineasProductos >= instalacionHasta)
        ? 0
        : Number(prod?.neu_punto_entrega_producto?.[0]?.precio_instalacion)
}


const discountedPrice = (price, discount, qty, string = true) => string ? Precio(Number(price - price * discount / 100) * qty) : Number(price - price * discount / 100) * qty


const handleSelectFiltersData = async () => {
  const roles = await axios(CONSTANT.queryUrlRoles,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data.filter(({ user_type }) => ['web'].includes(user_type)))
    .catch(err => {
      console.error(err)
      return []
    })

  const frontendId = await axios(CONSTANT.fetchFrontendId,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => data)
    .catch(err => {
      console.error(err)
      return []
    })

  const puntosEntrega = await axios(CONSTANT.queryUrlPuntosEntrega,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST',
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const tiposFacturacion = await axios(CONSTANT.queryUrlTiposFacturacion,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const tiposAtencion = await axios(CONSTANT.queryUrlTiposAtencion,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const formasPago = await axios(CONSTANT.queryUrlFormasPago,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const metodosPago = await axios(CONSTANT.queryUrlMetodosPago,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const divisas = await axios(CONSTANT.queryUrlDivisas,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const ejecutivos = await axios(CONSTANT.fetchEjecutivos,
    {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
      method: 'POST'
    })
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  const paises = await searchPaises()
    .then(({ data }) => [CONSTANT.unselectedObject, ...data])
    .catch(err => {
      console.error(err)
      return [CONSTANT.nullObject]
    })

  return { puntosEntrega, tiposFacturacion, tiposAtencion, formasPago, metodosPago, divisas, ejecutivos, frontendId, roles, paises }
}


const Create = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { resultados } = useSelector(state => state.productos)
  const { user } = useSelector(state => state.auth)
  const [form, setForm] = useState(CONSTANT.initialformState)
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
  const [fullImgModal, setFullImgModal] = useState({ display: false, data: null })

  const [cacheLastSelection, setCacheLastSelection] = useState({ punto_entrega: '', productos: [] })

  const [productosEncontrados, setProductosEncontrados] = useState(false)
  const [filteredProductosEncontrados, setFilteredProductosEncontrados] = useState(null)
  const [filteredProductosStockDisponible, setFilteredProductosStockDisponible] = useState(false)
  const [filteredProductosPublicados, setFilteredProductosPublicados] = useState(true)
  const [orderProductosPrecio, setOrderProductosPrecio] = useState(false)
  const [nombreInputProductoEncontrado, setNombreInputProductoEncontrado] = useState('')
  const [bodegaInputProductoEncontrado, setSkuInputProductoEncontrado] = useState('')

  const [productosSeleccionados, setProductosSeleccionados] = useState([])
  const [productosSeleccionadosFiltrados, setProductosSeleccionadosFiltrados] = useState(productosSeleccionados)
  const [aplicarInstalacion, setAplicarInstalacion] = useState(null)
  const [filterProducto, setFilterProducto] = useState('')

  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RELLENA LOS SELECTFILTERS Y SETEA VALORES DEFAULT
  useEffect(() => {
    handleSelectFiltersData().then(data => setSelectFilter(data))
  }, [])


  // EFECTOS PARA DEFAULT VALUES DE LOS SELECTFILTERS
  useEffect(() => {
    if (!selectFilter.ejecutivos.length || !selectFilter.formasPago.length ||
      !selectFilter.metodosPago.length || !selectFilter.tiposFacturacion.length || !user.id) return

    const defaultEjecutivo = selectFilter.ejecutivos.find(({ id }) => id === user.id)
    const defaultTipoFacturacion = selectFilter.tiposFacturacion.find(({ nombre }) => nombre.toLowerCase() === 'boleta')
    const defaultMetodoPago = selectFilter.metodosPago.find(({ nombre }) => nombre.toLowerCase() === 'webpay')
    const defaultDivisa = selectFilter.divisas.find(({ nombre }) => nombre.toLowerCase() === 'peso chileno')
    const defaultFormasPago = selectFilter.formasPago.find(({ nombre }) => nombre.toLowerCase() === 'contado')
    const defaultFrontend = selectFilter.frontendId.find(({ id }) => id === 1)
    const defaultRol = selectFilter.roles.find(({ name }) => name === 'particular')
    const defaultPais = selectFilter.paises.find(({ nombre }) => nombre.toLowerCase() === 'chile')

    setForm(form => ({
      ...form,
      ejecutivo_id: `${defaultEjecutivo?.id ?? '*NULL*'}-${defaultEjecutivo?.nombre ?? 'Sin seleccíón'}`,
      tipo_facturacion_id: `${defaultTipoFacturacion?.id ?? '*NULL*'}-${defaultTipoFacturacion?.nombre ?? 'Sin selección'}`,
      pais_cliente_id: `${defaultPais?.id ?? '*NULL*'}-${defaultPais?.nombre ?? 'Error'}`,
      metodo_pago_id: `${defaultMetodoPago?.id ?? '*NULL*'}-${defaultMetodoPago?.nombre ?? 'Sin selección'}`,
      divisa_id: `${defaultDivisa?.id ?? '*NULL*'}-${defaultDivisa?.nombre ?? 'Sin selección'}`,
      forma_pago_id: `${defaultFormasPago?.id ?? '*NULL*'}-${defaultFormasPago?.nombre ?? 'Sin selección'}`,
      frontend_id: `${defaultFrontend?.id ?? '*NULL*'}-${defaultFrontend?.nombre ?? 'Sin selección'}`,
      tipo_cliente_id: `${defaultRol?.id ?? '*NULL*'}-${defaultRol?.name ?? 'Sin selección'}`,
    }))
  }, [selectFilter.ejecutivos, selectFilter.formasPago, selectFilter.metodosPago, selectFilter.tiposFacturacion, selectFilter.divisas, selectFilter.frontendId, selectFilter.roles, selectFilter.paises, user])


  // EFECTO QUE SETEA VALORES A INICIALES EN EL DEMONTAJE
  useEffect(() => {
    return () => {
      setForm(CONSTANT.initialformState)
    }
  }, [])


  // EFECTO QUE RELLENA LA DATATABLE DE PRODUCTOS ENCONTRADOS
  useEffect(() => {
    if (!resultados?.data) return
    if (resultados?.data.length === 0) return setProductosEncontrados([])

    const orderedResults = resultados.data.map(prod => {
      const stockDisponible = prod?.stock_erp.length ? prod.stock_erp.reduce((prev, current, i) => {
        return prev += Number(current.stock_disponible)
      }, 0)
        : 0
      return prod = {
        ...prod,
        filtro_stock: stockDisponible,
        precio_unitario: Number(prod.precio_final),
        recargo: Number(prod?.neu_punto_entrega_producto?.[0]?.recargo),
        busqueda: `${prod.nombre} ${prod.sku}`,
      }
    })

    orderedResults.sort((a, b) => b.filtro_stock - a.filtro_stock)

    setProductosEncontrados(orderedResults)

  }, [resultados])


  // EFECTO QUE FILTRA LOS PRODUCTOS DE LA TABLA DE PRODUCTOS ENCONTRADOS
  useEffect(() => {
    if (!productosEncontrados.length) return setFilteredProductosEncontrados([])

    let filtered = productosEncontrados?.filter(producto => producto.busqueda.toLowerCase().includes(nombreInputProductoEncontrado.toLowerCase()))

    if (bodegaInputProductoEncontrado) filtered = filtered.filter(({ stock_erp }) => stock_erp.find(({ bodega }) => String(bodega) === bodegaInputProductoEncontrado))

    if (filteredProductosStockDisponible) filtered = filtered.filter(el => el.filtro_stock > 0)

    if (filteredProductosPublicados) filtered = filtered.filter(el => el.publicado === true)
    filtered = filtered.sort((a, b) => orderProductosPrecio
      ? b.precio_final - a.precio_final
      : a.precio_final - b.precio_final
    )

    setFilteredProductosEncontrados(filtered)
  }, [productosEncontrados, nombreInputProductoEncontrado, bodegaInputProductoEncontrado, filteredProductosStockDisponible, filteredProductosPublicados, orderProductosPrecio])


  // EFECTO FORMATEA Y RENDERIZA LOS PRODUCTOS SELECCIONADOS EN LA DATATABLE
  useEffect(() => {
    if (!productosEncontrados) return
    // COMPRUEBA SI TODOS LOS PRODUCTOS ESTAN SELECCIONADOS PARA ACTUALIZAR RENDER DEL CHECKBOX DE SELECCIONAR TODOS

    setProductosSeleccionadosFiltrados(productosSeleccionados
      .map(el => ({ ...el, filter_params: `${el.nombre} ${el.sku}` }))
      .filter(el => el.filter_params.toLocaleLowerCase().includes(filterProducto.toLocaleLowerCase()))
    )
  }, [filterProducto, productosSeleccionados, productosEncontrados])


  // RENDERIZA LOS PRODUCTOS LUEGO DE FILTRADOS EN LA DATATABLE
  useEffect(() => {
    if (!productosEncontrados) return

    setProductosSeleccionadosFiltrados([...productosSeleccionados
      .map(el => ({ ...el, filter_params: `${el.nombre} ${el.sku}` }))
      .filter(el => el.filter_params.toLocaleLowerCase().includes(filterProducto.toLocaleLowerCase()))
    ])
  }, [filterProducto, productosSeleccionados, productosEncontrados])



  // CONDICIONALES DE TIPO DE FACTURACION
  useEffect(() => {
    if (!selectFilter?.roles.length) return

    if (form?.tipo_facturacion_id?.includes('Factura')) {
      const { id, name } = selectFilter.roles.find(({ name }) => name === 'empresa')

      setForm(form => ({
        ...form,
        tipo_cliente_id: `${id}-${name}`
      }))
    }

    if (form?.tipo_facturacion_id?.includes('Boleta')) {
      const { id, name } = selectFilter.roles.find(({ name }) => name === 'particular')

      setForm(form => ({
        ...form,
        tipo_cliente_id: `${id}-${name}`
      }))
    }

  }, [form?.tipo_facturacion_id, selectFilter?.roles])


  // FUNCION MANEJADORA DE LOS INPUTS
  const handleInputChange = (e) => {
    const { name, value } = e.target

    if (name === 'puntoentrega_id') {
      const { aplicar_instalacion_hasta } = selectFilter.puntosEntrega.find(pe => String(pe.id) === String(value.split('-')[0]))
      setAplicarInstalacion(aplicar_instalacion_hasta === null ? null : Number(aplicar_instalacion_hasta))
      setProductosSeleccionados([])
      setCacheLastSelection({ productos: productosSeleccionados, punto_entrega: form.puntoentrega_id })
    }

    setForm({
      ...form,
      [name]: value
    })
  }


  // FUNCION MANEJADORA PARA VOLVER AL PUNTO DE ENTREGA ANTERIOR CON SUS PRODUCTOS SELECCIONADOS
  const handleLastSelection = () => {
    setProductosSeleccionados(cacheLastSelection.productos)

    setForm({ ...form, puntoentrega_id: cacheLastSelection.punto_entrega })
  }


  // FUNCION MANEJADORA PARA BUSCAR UN USUARIO POR EL INPUT EMAIL
  const handleFetchUserEmail = async (e) => {
    e.preventDefault()

    const queryEmail = form.email_cliente
    if (!validator.isEmail(queryEmail)) return dispatch(fireToaster({ title: 'Debe ingresar un email válido', icon: 'info' }))

    await axios(CONSTANT.queryUrlUsuarios + `?busqueda=${queryEmail}`,
      {
        headers: {
          'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
        },
        method: 'POST'
      })
      .then(({ data }) => {
        if (!data.length) return dispatch(fireToaster({ title: 'No se ha encontrado ningún registro', icon: 'info' }))
        if (data.length > 1) dispatch(fireToaster({ title: `${data.length} coincidencias encontradas`, html: `<b>Detalle: </b> Hay <b>${data.length}</b> registros que coinciden con la búsqueda, se aconseja ser más especifico.<br/>Los datos serán llenados con la primera coincidencia`, icon: 'info' }))
        const { nombre, email, fono, rut, id } = data[0]
        const [firstName, lastName] = nombre.split(' ')

        setForm({
          ...form,
          nombre_cliente: firstName ?? '',
          apellido_cliente: lastName ?? '',
          email_cliente: email ?? '',
          telefono_cliente: fono ?? '',
          rut_cliente: rut ?? '',
          usuario_id: id ?? ''
        })
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
				          Operación fallida
				          (${err.response.status} - ${err.response.statusText})
				        `,
          html: `
				          <b>Detalle: </b>
				          ${detalles.map(error => `<br /><i>-${error}</i>`)}
				        `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
  }


  /* Añade el producto seleccionado desde el arreglo de productos encontrado */
  const handleProductoSeleccionado = async (e) => {
    const { name } = e.target?.dataset ?? e.target
    if (!name) return
    const [id] = name.split('%-%')
    let isListed = productosSeleccionados.find(obj => String(obj.id) === id)
    let selected = productosEncontrados.find(obj => String(obj.id) === id)

    if (isListed) return dispatch(fireToaster({ title: 'Este producto ya se encuentra', icon: 'info' }))
    selected = { ...selected, cantidad: 1, seleccionado: true, porcentaje_descuento: 0 }

    selected = {
      ...selected,
      precio_unitario: validateOferta(selected)
        ? selected?.precio_oferta ?? selected.precio_final
        : selected.precio_final
    }

    setProductosSeleccionados([...productosSeleccionados, selected])
  }


  const handleQuitarProductosSeleccionados = (e) => {
    const { name } = e.target?.dataset ?? e.target
    const [id] = name.split('%-%')
    let isListed = productosSeleccionados.find(obj => String(obj.id) === id)
    setProductosSeleccionados(productosSeleccionados.filter((producto) => producto.id !== isListed.id))
    //setProductosSeleccionados([]) 
  }


  const handleInputFilterProducto = (e) => setFilterProducto(e.target.value);


  const handleInputProductoSeleccionado = (e, producto, target) => {
    let { value } = e.target
    const { id, nombre } = producto

    if (['recargo'].includes(target)) {
      if (value < 0) value = 0

      setProductosSeleccionados(productosSeleccionados.map(el => {
        return (el.id === id && el.nombre === nombre)
          ? {
            ...el,
            [target]: value
          }
          : el
      }))
      return
    }

    if (['cantidad'].includes(target)) {
      if (value < 1) value = 1
      const newProd = { ...producto, cantidad: value }
      setProductosSeleccionados(productosSeleccionados.map(el => {
        const precio_unitario = validateOferta(newProd) ? Number(el.precio_oferta) : Number(el.precio_final)
        return (el.id === id && el.nombre === nombre)
          ? {
            ...el,
            [target]: Number(value),
            precio_unitario
          }
          : el
      }))
      return
    }

    setProductosSeleccionados(productosSeleccionados.map(el => {
      return (el.id === id && el.nombre === nombre)
        ? {
          ...el,
          [target]: Number(value),
        }
        : el
    }))
  }


  const handleCounterButtonProductoSeleccionado = (producto, target) => {
    const { id, nombre, cantidad } = producto
    let newCantidad = cantidad
    switch (target) {
      case 'plus': newCantidad++
        break;

      case 'minus': newCantidad > 1 && newCantidad--
        break;

      default:
        break;
    }

    const newProd = { ...producto, cantidad: newCantidad }

    setProductosSeleccionados(productosSeleccionados.map(el => {
      const precio_unitario = validateOferta(newProd)
        ? el.precio_oferta
          ? Number(el.precio_oferta)
          : Number(el.precio_final)
        : Number(el.precio_final)

      return (el.id === id && el.nombre === nombre)
        ? {
          ...el,
          cantidad: Number(newCantidad),
          precio_unitario
        }
        : el
    }))
  }


  const fullImg = (data) => {
    setFullImgModal({ data, display: true })
  }


  // FUNCIÓN MANEJADORA QUE CREA EL REGISTRO EN LA BBDD
  const handleFetch = async (e, action) => {
    e.preventDefault()
    let badPrecioUnitario = []

    const {
      forma_pago_id,
      pais_cliente_id,
      metodo_pago_id,
      divisa_id,
      nombre_cliente,
      apellido_cliente,
      rut_cliente,
      email_cliente,
      telefono_cliente,
      rut_empresa,
      direccion_entrega,
      observaciones,
      tipo_facturacion_id,
      tipo_atencion_id,
      puntoentrega_id,
      razon_social,
      dias_vigencia_cotizacion,
      estatus,
      referencia_externa
    } = form

    let data = {
      nombre_cliente: `${nombre_cliente ?? ''} ${apellido_cliente ?? ''}`,
      email_cliente: email_cliente,
      telefono_cliente: telefono_cliente,
      rut_cliente,
      rut_empresa: form.tipo_facturacion_id.includes('Factura') ? rut_empresa : null,
      ejecutivo_id: idExtractor(form.ejecutivo_id),
      tipo_cliente_id: idExtractor(form.tipo_cliente_id),
      direccion_entrega,
      observaciones,
      razon_social,
      pais_cliente_id: idExtractor(pais_cliente_id),

      // TODO: preguntar a ricardinho las condiciones para la referencia_externa
      referencia_externa: referencia_externa.length ? referencia_externa : undefined,

      productos: productosSeleccionados.map(({ id, cantidad, precio_unitario, precio_final, sku, recargo, precio_oferta }) => {
        let cleanRecargo = !String(recargo).length ? null : Number(recargo)

        return { id, cantidad, precio_ingresado: precio_unitario, precio_final, precio_oferta, sku, recargo: cleanRecargo }
      }) ?? [],

      dias_vigencia_cotizacion: Number(dias_vigencia_cotizacion),
      forma_pago_id: idExtractor(forma_pago_id),
      metodo_pago_id: idExtractor(metodo_pago_id),
      divisa_id: idExtractor(divisa_id),
      tipo_facturacion_id: idExtractor(tipo_facturacion_id),
      tipo_atencion_id: idExtractor(tipo_atencion_id),
      puntoentrega_id: idExtractor(puntoentrega_id),

      estatus: Number(estatus) ? true : false
    }

    if (!form?.frontend_id.includes('*NULL*')) {
      data = {
        ...data,
        frontend_id: idExtractor(form.frontend_id),
      }
    }

    // REVISION DE PRECIOS LUEGO DE FORMATEADOS LOS PRODUCTOS QUE SE VAN A ENVIAR
    data.productos.forEach(prod => {
      const selectedProduct = productosSeleccionados.find(({ id }) => id === prod.id)
      const porcentajeValido = ((validateOferta(selectedProduct) ? prod.precio_oferta : prod.precio_final) * (100 - user?.porcentaje_descuento_max ?? 30)) / 100
      if (prod.precio_ingresado < porcentajeValido) return badPrecioUnitario = [...badPrecioUnitario, prod.sku]
    })

    if (badPrecioUnitario.length) {
      return dispatch(fireToaster({
        title: `Existen productos seleccionados con precios por debajo del límite de descuento permitido (${user?.porcentaje_descuento_max ?? 30}%)`,
        html: `
          Lista de productos (SKU):
          ${badPrecioUnitario.map(sku => (
          `<br /><b>✖ ${sku}</b>`
        ))}
        `,
        icon: 'warning',
        timer: 180000
      }))
    }
    if (!productosSeleccionados.length) {
      const toasterContent = {
        title: 'No hay productos seleccionados',
        text: `Por favor, seleccione al menos un producto para proseguir`,
        icon: 'info'
      }
      return dispatch(fireToaster(toasterContent))
    }
    if (data.dias_vigencia_cotizacion < 1 || isNaN(data.dias_vigencia_cotizacion)) {
      const toasterContent = {
        title: 'Días de vigencia inválidos',
        text: `Por favor, seleccione una cantidad de días mayor a uno (1)`,
        icon: 'info'
      }
      return dispatch(fireToaster(toasterContent))
    }
    if (data.puntoentrega_id < 1 || isNaN(data.puntoentrega_id)) {
      const toasterContent = {
        title: 'Punto de entrega inválido',
        text: `Por favor, seleccione un punto de entrega`,
        icon: 'info'
      }
      return dispatch(fireToaster(toasterContent))
    }
    if (data.tipo_facturacion_id < 1 || isNaN(data.tipo_facturacion_id)) {
      const toasterContent = {
        title: 'Tipo de facturación inválido',
        text: `Por favor, seleccione un tipo de facturación`,
        icon: 'info'
      }
      return dispatch(fireToaster(toasterContent))
    }
    if (data.tipo_atencion_id < 1 || isNaN(data.tipo_atencion_id)) {
      const toasterContent = {
        title: 'Tipo de atención inválido',
        text: `Por favor, seleccione un tipo de atención`,
        icon: 'info'
      }
      return dispatch(fireToaster(toasterContent))
    }
    if (data.nombre_cliente.length < 1) {
      const toasterContent = {
        title: 'Nombre de cliente vacío',
        text: `Por favor, ingrese un nombre`,
        icon: 'info'
      }
      return dispatch(fireToaster(toasterContent))
    }

    setIsConsulting(true)

    axios(CONSTANT.queryUrl, {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
      },
      data
    })
      .then(async res => {
        let toasterContent = {
          title: 'Operación realizada',
          text: `${CONSTANT.title}: #${res.data.id} creado con éxito`,
          icon: 'success'
        }

        if (action === CONSTANT.fetchAction.guardar_enviar_con_totales || action === CONSTANT.fetchAction.guardar_enviar_sin_totales) {
          if (!res?.data?.id) return dispatch(fireToaster({
            title: 'Operación fallida',
            text: 'No se encontró un ID para la cotización',
            icon: 'error'
          }))

          const enviarConfig = {
            method: 'POST',
            headers: {
              'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null,
            },
            data: {
              correo: form?.email_cliente,
              totalizacion: CONSTANT.fetchAction.guardar_enviar_con_totales ? 1 : 0,
              cotizacion_id: Number(res?.data?.id)
            }
          }

          await axios(CONSTANT.queryUrl + '/enviar', enviarConfig)
            .then((_) => {
              toasterContent = {
                title: 'Operación realizada',
                text: `${CONSTANT.title}: Cotización #${res.data.id} creada y enviada con éxito`,
                icon: 'success'
              }
            })
            .catch(err => {
              console.error(err)
              const { errores } = err.response.data
              let detalles = []
              Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

              const toasterContent = {
                title: `
                  Operación fallida
                  (${err.response.status} - ${err.response.statusText})
                `,
                html: `
                  <b>Detalle: </b>
                  ${detalles.map(error => `<br /><i>-${error}</i>`)}
                `,
                icon: 'error'
              }
              dispatch(fireToaster(toasterContent))
            })
        }

        dispatch(fireToaster(toasterContent))
        navigate(CONSTANT.redirectUrl)
      })
      .catch(err => {
        console.error(err)
        const { errores } = err.response.data
        let detalles = []
        Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

        const toasterContent = {
          title: `
	          Operación fallida
	          (${err.response.status} - ${err.response.statusText})
	        `,
          html: `
	          <b>Detalle: </b>
	          ${detalles.map(error => `<br /><i>-${error}</i>`)}
	        `,
          icon: 'error'
        }
        dispatch(fireToaster(toasterContent))
      })
      .finally(() =>
        setIsConsulting(false)
      )
  }


  return (
    <div className="row">
      {/* MARK: MODAL IMAGEN AMPLIADA */}
      <Modal handleValue={() => setFullImgModal({ data: null, display: false })} display={fullImgModal.data}>
        {fullImgModal.display &&
          <>
            <span><b className="fs-4">{fullImgModal?.data?.nombre ?? 'Sin nombre'}</b>({fullImgModal?.data?.sku ?? 'Sin SKU'})</span>
            <p className="mb-25">{fullImgModal?.data?.neu_categoria?.nombre ?? 'Sin categoría'}</p>


            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <span className="w-fit">Precio unit.: <b>{Precio(fullImgModal?.data?.precio_unitario)}</b></span>

                <span>Oferta: <b className={`tag h-fit ${fullImgModal?.data?.neu_oferta_producto?.[0]?.id ? 'success' : 'yellow'} px-50`}>{fullImgModal?.data?.neu_oferta_producto?.[0]?.neu_oferta?.nombre ?? 'N/A'}</b></span>
              </div>

              {fullImgModal?.data?.precio_oferta
                ? <span className="fs-4">Precio oferta: <b>{Precio(fullImgModal?.data?.precio_oferta)}</b></span>
                : <span className="fs-4">Precio final: <b>{Precio(fullImgModal?.data?.precio_final)}</b></span>
              }
            </div>

            <img
              className="m-auto p-50 w-100"
              src={`${BASE_URL}storage/${fullImgModal?.data.neu_producto_imagenes[0].imagen}`}
              alt={fullImgModal?.data?.nombre}
            />

            <div className="d-flex justify-content-between">
              <img
                className="m-auto p-50 w-100"
                style={{ height: '80px', objectFit: 'contain' }}
                src={`${BASE_URL}storage/${fullImgModal?.data.neu_producto_atributos?.[0].neu_valor_atributo?.logo}`}
                alt={fullImgModal?.data.neu_producto_atributos?.[0].neu_valor_atributo?.nombre}
              />
            </div>
          </>
        }
      </Modal>

      <div className="col col-md-12 mx-auto" >
        <div className='col'>
          <Button text={CONSTANT.title} icon='ArrowLeft' style='flat' isLink url={CONSTANT.redirectUrl} />
        </div>

        {/* MARK: FORMULARIO */}
        <CustomForm size="" title={CONSTANT.componentTitle}>
          {selectFilter.puntosEntrega.length && selectFilter.tiposAtencion.length && selectFilter.formasPago.length &&
            selectFilter.tiposFacturacion.length && selectFilter.metodosPago.length && selectFilter.divisas.length && selectFilter.frontendId.length

            ? <>
              <div className="row">
                <div className="col-4 border-end border-2">
                  <div className="row">
                    {/* CLIENTE */}
                    <h2 className="text-center text-black fs-4 py-75">Cliente</h2>

                    <SelectFilter
                      name='pais_cliente_id'
                      labelText="* País"
                      size="col-12"
                      sizeDesk="col-md-6"
                      handleValue={handleInputChange}
                      value={form.pais_cliente_id?.split('-')[1] ?? 'Seleccione'}
                      optionObj={selectFilter.paises?.map(({ id, nombre }) => ({ id, name: nombre ?? 'Error' }))}
                    />

                    <SelectFilter
                      name='frontend_id'
                      labelText="* Frontend"
                      size="col-12"
                      sizeDesk="col-md-6"
                      handleValue={handleInputChange}
                      value={form.frontend_id?.split('-')[1] ?? 'Seleccione'}
                      optionObj={selectFilter.frontendId?.map(({ id, nombre }) => ({ id, name: nombre ?? 'Error' }))}
                    />

                    <FormInput
                      name='nombre_cliente'
                      labelText="* Nombre"
                      placeholder="Nombre"
                      size="col-12"
                      sizeDesk="col-md-6"
                      handleValue={handleInputChange}
                      value={form.nombre_cliente}
                    />

                    <FormInput
                      name='apellido_cliente'
                      labelText="Apellido"
                      placeholder="Apellido"
                      size="col-12"
                      sizeDesk="col-md-6"
                      handleValue={handleInputChange}
                      value={form.apellido_cliente}
                    />

                    <div className="d-flex gap-1">
                      <FormInput
                        type="email"
                        name='email_cliente'
                        placeholder='Email'
                        labelText="Email"
                        size="col-10"
                        sizeDesk="col-md-6"
                        handleValue={handleInputChange}
                        value={form.email_cliente}
                      />
                      <Button icon='Search' className='my-auto' onClick={handleFetchUserEmail} />
                    </div>

                    <FormInput
                      type="number"
                      name='telefono_cliente'
                      labelText="Teléfono"
                      placeholder="Teléfono"
                      size="col-12"
                      sizeDesk="col-md-6"
                      handleValue={handleInputChange}
                      value={form.telefono_cliente}
                    />

                    <FormInput
                      name='rut_cliente'
                      labelText="RUT cliente"
                      placeholder="RUT..."
                      size="col-12"
                      sizeDesk="col-md-6"
                      handleValue={handleInputChange}
                      value={form.rut_cliente}
                    />

                    <SelectFilter
                      name='roles'
                      labelText="* Tipo usuario"
                      size="col-12"
                      sizeDesk="col-md-12"
                      handleValue={handleInputChange}
                      value={form.tipo_cliente_id?.split('-')[1] ?? 'Seleccione'}
                      optionObj={selectFilter.roles?.map(({ id, name }) => ({ id, name: name ?? 'Error' }))}
                    />

                    {form?.tipo_facturacion_id?.includes('Factura')

                      ? <>
                        <FormInput
                          name='rut_empresa'
                          labelText="RUT empresa"
                          placeholder="RUT empresa"
                          size="col-12"
                          sizeDesk="col-md-12"
                          handleValue={handleInputChange}
                          value={form.rut_empresa}
                        />

                        <FormInput
                          name='razon_social'
                          labelText="Razón social"
                          placeholder="Razón social"
                          size="col-12"
                          sizeDesk="col-md-12"
                          handleValue={handleInputChange}
                          value={form.razon_social}
                        />
                      </>

                      : <></>
                    }
                  </div>

                  <h2 className="text-center text-black fs-4 py-75">Ejecutivo</h2>
                  <SelectFilter
                    name='ejecutivo_id'
                    labelText="* Ejecutivo"
                    size="col-12"
                    sizeDesk="col-md-12"
                    handleValue={handleInputChange}
                    value={form.ejecutivo_id?.split('-')[1] ?? 'Seleccione'}
                    optionObj={selectFilter.ejecutivos?.map(({ id, nombre }) => ({ id, name: nombre ?? 'Error' }))}
                  />
                </div>

                <div className="col-8">
                  <h2 className="text-center text-black fs-4 py-75">Cotización</h2>
                  <div className="row">
                    <div className="d-flex gap-1">
                      <SelectFilter
                        name='puntoentrega_id'
                        labelText="* Punto de entrega"
                        size="col-12"
                        sizeDesk="col-md-11"
                        handleValue={handleInputChange}
                        value={form.puntoentrega_id?.split('-')[1] ?? 'Seleccione'}
                        optionObj={selectFilter?.puntosEntrega?.map(({ id, direccion, neu_tipo_destino, tipo_destino, comuna }) => ({ id, name: `${neu_tipo_destino?.nombre ?? tipo_destino?.nombre ?? 'Sin tipo destino'} | ${direccion ?? 'Sin dirección'} | ${comuna?.nombre ?? 'Sin comuna'}, ${comuna?.neu_region?.nombre ?? comuna?.region?.nombre ?? 'Sin región'}` }))}
                      />

                      <Button icon={'RotateCcw'} className={'w-fit my-auto col-2'} onClick={() => handleLastSelection()} disabled={!cacheLastSelection?.productos?.length} />
                    </div>

                    <div className="col-12 col-md-4">
                      <SelectFilter
                        name='tipo_atencion_id'
                        labelText="* Tipo de atención"
                        size="col-12"
                        sizeDesk="col-md-12"
                        handleValue={handleInputChange}
                        value={form.tipo_atencion_id?.split('-')[1] ?? 'Seleccione'}
                        optionObj={selectFilter.tiposAtencion?.map(({ id, nombre }) => ({ id, name: nombre }))}
                      />

                      <SelectFilter
                        name='divisa_id'
                        labelText="Divisa"
                        size="col-12"
                        sizeDesk="col-md-12"
                        handleValue={handleInputChange}
                        value={form.divisa_id?.split('-')[1] ?? 'Seleccione'}
                        optionObj={selectFilter.divisas?.map(({ id, nombre }) => ({ id, name: nombre }))}
                      />
                    </div>

                    <div className="col-12 col-md-4">
                      <FormInput
                        type="number"
                        name='dias_vigencia_cotizacion'
                        margin='mb-1'
                        placeholder='Escriba una cantidad'
                        labelText="* Días de vigencia"
                        size="col-12"
                        sizeDesk="col-md-12"
                        handleValue={handleInputChange}
                        value={form.dias_vigencia_cotizacion}
                      />

                      <SelectFilter
                        name='forma_pago_id'
                        labelText="Forma de pago"
                        size="col-12"
                        sizeDesk="col-md-12"
                        handleValue={handleInputChange}
                        value={form.forma_pago_id?.split('-')[1] ?? 'Seleccione'}
                        optionObj={selectFilter.formasPago?.map(({ id, nombre }) => ({ id, name: nombre }))}
                      />
                    </div>

                    <div className="col-12 col-md-4">
                      <SelectFilter
                        name='tipo_facturacion_id'
                        labelText="* Tipo de facturación"
                        size="col-12"
                        sizeDesk="col-md-12"
                        handleValue={handleInputChange}
                        value={form.tipo_facturacion_id?.split('-')[1] ?? 'Seleccione'}
                        optionObj={selectFilter.tiposFacturacion?.map(({ id, nombre }) => ({ id, name: nombre }))}
                      />

                      <SelectFilter
                        name='metodo_pago_id'
                        labelText="Método de pago"
                        size="col-12"
                        sizeDesk="col-md-12"
                        handleValue={handleInputChange}
                        value={form.metodo_pago_id?.split('-')[1] ?? 'Seleccione'}
                        optionObj={selectFilter.metodosPago?.map(({ id, nombre }) => ({ id, name: nombre }))}
                      />
                    </div>

                    <TextareaInput
                      name='observaciones'
                      labelText="Observaciones"
                      placeholder="Coloque aquí las observaciones..."
                      rows={5}
                      size="col-12"
                      sizeDesk='col-md-8'
                      handleValue={handleInputChange}
                      value={form.observaciones}
                    />

                    <FormInput
                      name='referencia_externa'
                      labelText="Referencia externa"
                      placeholder="Referencia externa"
                      size="col-12"
                      sizeDesk="col-md-4"
                      handleValue={handleInputChange}
                      value={form.referencia_externa}
                    />
                  </div>
                </div>
              </div>
            </>
            : <CustomLoader />
          }
        </CustomForm>

        <div className="row">
          {/* MARK: PRODUCTOS FILTROS */}
          <ProductosFiltros
            isAsignacionMasiva={true}
            isCotizaciones={true}
            puntoEntrega={form.puntoentrega_id?.split('-')[0] ?? null}
            isOpen
          />
          <div className="mb-2"></div>

          {/* MARK: PRODUCTOS ENCONTRADOS */}
          <AccordionTable
            title={`Productos encontrados (${productosEncontrados?.length ?? 0} encontrados / ${filteredProductosEncontrados?.length ?? 0} filtrados)`}
            classes='col-12 col-md-6 mb-3'
          >
            <div className={`card-datatable table-responsive pt-0 custom-scrollbar flex-shrink-0 dataTable custom-scrollbar overflow-auto`}>
              <table className="position-relative user-list-table table no-footer dtr-column z-10">
                <thead className="table-light position-sticky top-0 w-100 z-100 shadow">
                  <tr role="row">
                    <th className="col-10">
                      <div className='d-flex gap-1'>
                        <div className="d-flex flex-column">
                          <p className="mb-25">Stock</p>
                          <CheckBoxInput
                            labelText=''
                            value={filteredProductosStockDisponible}
                            handleValue={() => setFilteredProductosStockDisponible(!filteredProductosStockDisponible)}
                            classes='mx-auto'
                          />
                        </div>

                        <div className="d-flex flex-column">
                          <p className="mb-25">Publi</p>
                          <CheckBoxInput
                            labelText=''
                            value={filteredProductosPublicados}
                            handleValue={() => setFilteredProductosPublicados(!filteredProductosPublicados)}
                            classes='mx-auto'
                          />
                        </div>

                        <div className="d-flex flex-column">
                          <p className="mb-25">Precio ({orderProductosPrecio ? 'DESC' : 'ASC'})</p>
                          <CheckBoxInput
                            labelText=''
                            value={orderProductosPrecio}
                            handleValue={() => setOrderProductosPrecio(!orderProductosPrecio)}
                            classes='mx-auto'
                          />
                        </div>

                        <FormInput
                          value={nombreInputProductoEncontrado}
                          placeholder='Producto'
                          labelText=''
                          handleValue={(e) => setNombreInputProductoEncontrado(e.target.value)}
                          sizeDesk='col'
                          classes='text-capitalize sub-text-3 font-weight-normal'
                          margin='my-auto'
                        />
                      </div>
                    </th>

                    <th className="col-2 px-50">
                      <div className='d-flex'>
                        <FormInput
                          value={bodegaInputProductoEncontrado}
                          placeholder='Bodega'
                          labelText=''
                          handleValue={(e) => setSkuInputProductoEncontrado(e.target.value)}
                          sizeDesk='col'
                          classes='text-capitalize sub-text-3 font-weight-normal'
                          margin='my-auto'
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProductosEncontrados?.length
                    ? filteredProductosEncontrados?.map((data, i) => {
                      return (
                        <React.Fragment key={`${i}-producto-${data?.id}-${data?.nombre}`}>
                          <tr
                            className={`odd data-selector ${data.seleccionado ? 'selected' : ''}`}
                            onClick={handleProductoSeleccionado}
                            data-name={`${data?.id}%-%${data?.nombre}`}
                            style={{ backgroundColor: data.filtro_stock ? '#fff' : '#fcdede', borderBottom: 'transparent' }}
                            key={`tr1-producto-${data?.id}-${data?.nombre}`}
                          >

                            <td data-name={`${data?.id}%-%${data?.nombre}`}>
                              <div className="d-flex justify-content-between" data-name={`${data?.id}%-%${data?.nombre}`}>
                                <div className="d-flex gap-1" data-name={`${data?.id}%-%${data?.nombre}`}>
                                  <div className="d-flex flex-column gap-1">
                                    <span className={`tag fs-7 mx-auto px-25 ${data?.publicado ? 'success' : 'red'}`}>{data?.publicado ? 'Publicado' : 'Despublicado'}</span>

                                    {data?.neu_producto_imagenes?.length
                                      ? <div className="position-relative">
                                        <img
                                          className="height-listado border rounded-2 p-50 bg-white"
                                          style={{ width: '100px' }}
                                          src={`${BASE_URL}storage/${data.neu_producto_imagenes[0].imagen}`}
                                          onClick={() => fullImg(data)}
                                          alt={data?.nombre}
                                        />

                                        <div className='position-absolute data-selector' style={{ top: '5%', left: '5%' }}
                                          onClick={() => fullImg(data)}
                                        >
                                          <Icon icon='Search' c />
                                        </div>
                                      </div>
                                      : <span className="my-auto border rounded-2 p-50" data-name={`${data?.id}%-%${data?.nombre}`} style={{ width: '100px' }}>Sin imagen</span>
                                    }
                                  </div>

                                  <div className='d-flex flex-column my-auto' data-name={`${data?.id}%-%${data?.nombre}`}>
                                    <span data-name={`${data?.id}%-%${data?.nombre}`}>{data?.neu_categoria?.nombre}</span>

                                    <span data-name={`${data?.id}%-%${data?.nombre}`}>SKU: <b data-name={`${data?.id}%-%${data?.nombre}`}>{data?.sku}</b></span>

                                    <a href={`/productos/detalle/${data?.id}`} target="_blank" rel="noreferrer" className="rounded-2 border p-25 data-selector">
                                      <b>{data?.nombre.toUpperCase()}</b>
                                    </a>

                                    <span data-name={`${data?.id}%-%${data?.nombre}`}>
                                      {data?.precio_oferta &&
                                        <b data-name={`${data?.id}%-%${data?.nombre}`} className='fs-4'>{Precio(data?.precio_oferta)} - </b>
                                      }
                                      <span data-name={`${data?.id}%-%${data?.nombre}`} className='fw-bold'>{Precio(data?.precio_final)}</span>
                                    </span>

                                    <span data-name={`${data?.id}%-%${data?.nombre}`}>Stock web: <b data-name={`${data?.id}%-%${data?.nombre}`}>{data?.stock_propio ?? 'N/A'}</b></span>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="ps-3" data-name={`${data?.id}%-%${data?.nombre}`}>

                              <div className="d-flex flex-column mt-50" data-name={`${data?.id}%-%${data?.nombre}`}>
                                <span data-name={`${data?.id}%-%${data?.nombre}`}>Oferta:</span>
                                <b data-name={`${data?.id}%-%${data?.nombre}`}>{data?.neu_oferta_producto?.[0]?.neu_oferta?.nombre ?? 'N/A'}</b>
                              </div>
                            </td>

                          </tr>

                          <tr style={{ borderBottom: '1px solid #041c62' }} key={`tr2-producto-${data?.id}-${data?.nombre}`}>
                            <td colSpan={2} className="pt-0 px-50" style={{ backgroundColor: data.filtro_stock ? '#fff' : '#fcdede' }} >
                              <AccordionTable
                                title={`Stock bodegas: ${data.filtro_stock}`}
                                style={{ backgroundColor: '#eee', color: '#555' }}
                                isOpen={data.filtro_stock ? true : false}
                              >
                                <Table pb={false}>
                                  <thead>
                                    <tr key={`tr3-producto-${data?.id}-${data?.nombre}`}>
                                      <th>Bodega</th>
                                      <th>Qty disponible</th>
                                      <th>Qty Física</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.stock_erp.length
                                      ? data.stock_erp.map(({ bodega, stock_disponible, stock_fisico }) => (
                                        <tr key={`erp-${data?.id}-${bodega}`}>
                                          <td>
                                            <b>{bodega}</b>
                                          </td>
                                          <td>
                                            <b>{stock_disponible}</b>
                                          </td>
                                          <td>
                                            <b>{stock_fisico}</b>
                                          </td>
                                        </tr>
                                      ))
                                      : <tr><td colSpan={3}>No hay disponibilidad</td></tr>
                                    }
                                  </tbody>
                                </Table>
                              </AccordionTable>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })
                    : !productosEncontrados
                      ? <tr>
                        <td colSpan={2}><CustomLoader /></td>
                      </tr>

                      : <tr>
                        <td colSpan={2}><NoResults /></td>
                      </tr>
                  }
                </tbody>
              </table>
            </div>
          </AccordionTable>

          {/* MARK: PRODUCTOS SELECCIONADOS */}
          <AccordionTable
            title={`Productos seleccionados (${productosSeleccionados.length})`}
            classes='col-12 col-md-6'
          >
            <div className={`card-datatable table-responsive pt-0 custom-scrollbar overflow-auto dataTable flex-shrink-0`} style={{ minHeight: '150px' }}>
              <table className="user-list-table table dataTable no-footer dtr-column z-10 h-100" >
                <thead className="table-light position-sticky top-0 z-10">
                  <tr role="row">
                    <th className='col'>
                      <div className='d-flex'>
                        <button
                          onClick={() => setProductosSeleccionados([])}
                          className='m-auto bg-warning rounded-2 text-white fw-bolder p-25 px-50 mt-2'
                        >
                          Borrar productos
                        </button>
                        <FormInput
                          name='producto'
                          value={filterProducto}
                          placeholder='Buscar...'
                          labelText='PRODUCTO - SKU'
                          handleValue={handleInputFilterProducto}
                          sizeDesk='col-10'
                          classes='text-capitalize sub-text-3 font-weight-normal'
                          margin='my-auto ms-50'
                        />
                        <div className='mt-2 pt-75'>
                          <Button color={'danger'} icon='Trash' className={'ms-50 my-auto p-25'} onClick={() => setFilterProducto('')} />
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(selectFilter?.puntosEntrega?.find(({ id }) => (Number(id) === Number(form?.puntoentrega_id?.split('-')[0])))?.es_tarifa_plana) ?
                    <tr>
                      <td colSpan={1} className="py-25 text-center fw-bolder" style={{ backgroundColor: '#d0f7cb', color: '#666' }}>
                        El punto de entrega posee tarifa plana ({renderRecargoInstalacion(Number(productosSeleccionados?.sort((a, b) => Number(b?.neu_punto_entrega_producto[0]?.precio_instalacion) - Number(a?.neu_punto_entrega_producto[0]?.precio_instalacion))?.[0]?.neu_punto_entrega_producto[0]?.precio_instalacion))})
                      </td>
                    </tr>
                    : <></>
                  }
                  {productosSeleccionadosFiltrados.map((data, i) => (
                    <React.Fragment key={`${i}-producto-selected-${data.id}-${data.nombre}`}>
                      <tr className="odd multi-data" style={{ borderBottom: 'transparent' }}>
                        <td className="p-0 pt-1" >
                          <div className="d-flex gap-1">
                            <div className="d-flex justify-content-center">
                              <CheckBoxInput
                                labelText=''
                                value={data.seleccionado}
                                handleValue={handleQuitarProductosSeleccionados}
                                name={`${data.id}%-%${data.nombre}`}
                                classes='my-auto ms-50'
                              />
                            </div>

                            {data?.neu_producto_imagenes?.length
                              ? <img
                                className="height-listado border rounded-2 p-50 bg-white"
                                style={{ width: '100px' }}
                                src={`${BASE_URL}storage/${data.neu_producto_imagenes[0].imagen}`}
                                alt={data?.nombre}
                                onClick={() => fullImg(data)}
                              />
                              : <span className="my-auto border rounded-2 p-50" style={{ width: '100px' }}>Sin imagen</span>
                            }

                            <div className='d-flex flex-column'>
                              <b>{data?.nombre.toUpperCase() ?? 'N/A'}</b>
                              <span className='sub-text-3 text-lgray'>SKU: <b>{data?.sku ?? 'N/A'}</b> | Stock: <b>{data?.stock_propio ?? 'N/A'}</b></span>
                              <span className='sub-text-3 text-lgray'>Recargo: <b>{renderRecargoInstalacion(data?.neu_punto_entrega_producto[0]?.recargo)}</b> | Instalación: <b>{renderRecargoInstalacion(data?.neu_punto_entrega_producto[0]?.precio_instalacion)}</b></span>
                              <span className='sub-text-3 text-lgray'>Instalación permanente: <b>{data?.neu_punto_entrega_producto?.[0]?.instalacion_permanente ? 'Sí' : 'No' ?? 'N/A'}</b></span>
                            </div>

                            <div className="d-flex flex-column justify-content-center text-center m-auto me-2 p-1 border rounded-3"
                              style={{ backgroundColor: validateOferta(data) ? '#7cfc9e' : '#e8edea' }}
                            >
                              <p>Oferta:</p>
                              <b>{data?.neu_oferta_producto?.[0]?.neu_oferta?.nombre ?? 'N/A'}</b>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={5} style={{ borderBottom: '1px solid #041c62' }}>
                          <div className="d-flex justify-content-between gap-1">
                            <div className="d-flex flex-column">

                              <div className="text-center">
                                <span>Cantidad</span>
                              </div>

                              <div className='d-flex fs-2' style={{ width: '180px' }}>
                                <Button icon='Minus' className='m-auto' onClick={() => handleCounterButtonProductoSeleccionado(data, 'minus')} />
                                <FormInput
                                  type='number'
                                  value={data.cantidad ?? undefined}
                                  margin='my-auto'
                                  classes='d-flex'
                                  size='col-4'
                                  handleValue={(e) => handleInputProductoSeleccionado(e, data, 'cantidad')}
                                />
                                <Button icon='Plus' className='m-auto' onClick={() => handleCounterButtonProductoSeleccionado(data, 'plus')} />
                              </div>
                            </div>

                            <div className="col-2">
                              <p className="mb-25">Precio unit.</p>
                              <FormInput
                                type='number'
                                value={data?.precio_unitario ?? undefined}
                                margin='my-auto'
                                classes='d-flex'
                                handleValue={(e) => handleInputProductoSeleccionado(e, data, 'precio_unitario')}
                              />
                            </div>

                            <div>
                              <p className="mb-25">Total</p>
                              <b>{Precio(data.precio_unitario * data.cantidad)}</b>
                            </div>

                            <div>
                              <p className="mb-25">Instalación</p>
                              {selectFilter?.puntosEntrega?.find(({ id }) => (Number(id) === Number(form?.puntoentrega_id?.split('-')[0])))?.es_tarifa_plana
                                ? <b>Tarifa plana</b>
                                : <b>{renderRecargoInstalacion(data?.neu_punto_entrega_producto[0]?.precio_instalacion, data.cantidad)}</b>
                              }
                            </div>

                            <div className="col-2">
                              <p className="mb-25">Recargo</p>
                              {user.permisos['cotizaciones.recargo-dinamico']
                                ? <FormInput
                                  type='number'
                                  value={data.recargo ?? undefined}
                                  margin='my-auto'
                                  classes='d-flex'
                                  handleValue={(e) => handleInputProductoSeleccionado(e, data, 'recargo')}
                                />
                                : <b>{renderRecargoInstalacion(data?.neu_punto_entrega_producto[0]?.recargo, data.cantidad)}</b>
                              }
                            </div>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </AccordionTable>
        </div>

        {/* MARK: LEYENDA DE TOTALIZACION DE PRODUCTO */}
        <div className="col justify-content-between gap-1 p-1 px-2 mb-50 bg-white rounded-3 border">
          <div>
            <div>
              <span className="fs-3">Cantidad de productos: <b className="fs-3">{productosSeleccionados.reduce((prev, current) => prev += current.cantidad, 0)}</b> </span>
              {!productosSeleccionados.find(prod => prod?.neu_punto_entrega_producto?.[0]?.instalacion_permanente > 0) && !productosSeleccionados.find(data => data?.neu_punto_entrega_producto?.[0]?.instalacion_permanente > 0)
                ? <span>(Monto de instalación hasta: <b>{Precio(aplicarInstalacion)}</b>) </span>
                : <></>
              }
              {selectFilter?.puntosEntrega?.find(({ id }) => (Number(id) === Number(form?.puntoentrega_id?.split('-')[0])))?.es_tarifa_plana
                ? <span>(Tarifa plana: <b>{renderRecargoInstalacion(productosSeleccionados?.sort((a, b) => Number(b?.neu_punto_entrega_producto[0]?.precio_instalacion) - Number(a?.neu_punto_entrega_producto[0]?.precio_instalacion))?.[0]?.neu_punto_entrega_producto[0]?.precio_instalacion)}</b>)</span>
                : <></>
              }
            </div>
            <hr className='m-0 mb-1' />
            <Table>
              <thead>
                <tr>
                  <th>Producto</th>
                  <th className="text-center">Cantidad</th>
                  <th>Precio línea</th>
                  <th>Instalación</th>
                  <th>Recargo</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {productosSeleccionados.map(producto => {
                  const hasTarifaPlana = selectFilter?.puntosEntrega?.find(({ id }) => (Number(id) === Number(form?.puntoentrega_id?.split('-')[0])))?.es_tarifa_plana

                  return (
                    <tr className="mb-25" key={`${producto?.id}-${producto?.nombre ?? 'producto'}`}>
                      {/* PRODUCTO */}
                      <td>
                        <b>{producto.nombre.toUpperCase()}</b>
                      </td>

                      {/* CANTIDAD */}
                      <td className="text-center">
                        <b>{producto.cantidad}</b>
                      </td>

                      {/* PRECIO LINEA */}
                      <td>
                        <b>{PrecioRaw(Number(producto.precio_unitario * producto.cantidad))}</b>
                      </td>

                      {/* INSTALACION */}
                      <td>
                        <b>
                          {renderInstalacionTotal(hasTarifaPlana, aplicarInstalacion, productosSeleccionados, producto)}
                        </b>
                      </td>

                      {/* RECARGO */}
                      <td>
                        <b>{producto?.neu_punto_entrega_producto[0]?.recargo
                          ? PrecioRaw(Number(producto.cantidad) * Number(producto?.recargo ?? 0))
                          : 0}</b>
                      </td>

                      {/* SUBTOTAL */}
                      <td>
                        <b>
                          {Precio(discountedPrice(
                            producto.precio_unitario,
                            producto.porcentaje_descuento,
                            producto.cantidad,
                            false
                          )
                            +
                            (
                              (renderSubtotalProducto(productosSeleccionados, hasTarifaPlana, aplicarInstalacion, producto) +
                                Number(producto?.recargo ?? 0))
                            )
                            *
                            Number(producto.cantidad))
                          }
                        </b>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            <div className="mt-1 d-flex justify-content-between">
              <span className="fs-3">Total: <b className="fs-3">
                {Precio(productosSeleccionados?.reduce((prev, current) => {
                  const hasTarifaPlana = selectFilter?.puntosEntrega?.find(({ id }) => (Number(id) === Number(form?.puntoentrega_id?.split('-')[0])))?.es_tarifa_plana

                  const aditionals = renderSubtotalProducto(productosSeleccionados, hasTarifaPlana, aplicarInstalacion, current) + Number(current?.recargo ?? 0)
                  const currentPrice = Number(current.precio_unitario)
                  const discount = currentPrice * current.porcentaje_descuento / 100
                  return prev += ((currentPrice - discount) + aditionals) * current.cantidad
                }, 0)
                )} CLP
              </b>
              </span>

              <div className="d-flex gap-1">
                <Button disabled={isConsulting} text="Guardar" className='m-auto' onClick={(e) => handleFetch(e, CONSTANT.fetchAction.guardar)} />
                <Button text="Guardar y enviar con totales" disabled={!form.email_cliente} className='m-auto' color='success' onClick={(e) => handleFetch(e, CONSTANT.fetchAction.guardar_enviar_con_totales)} />
                <Button text="Guardar y enviar sin totales" disabled={!form.email_cliente} className='m-auto' color='warning' onClick={(e) => handleFetch(e, CONSTANT.fetchAction.guardar_enviar_sin_totales)} />
                {/* <DropdownWrapper icon="MoreHorizontal" classes='m-auto'>
                </DropdownWrapper> */}
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Create;