import PropTypes from 'prop-types';
import Icon from './Icon';

const regexNumber = /^-?\d+\.?\d*$/


const numbersOnly = (v) => {
   if (String(v).length && !regexNumber.test(v)) return
   return v
}

const FormInput = ({
   type, placeholder, labelText,
   value, name, handleValue, min, max,
   size, sizeDesk, sizeTablet,
   classes, margin, withButton,
   isDisabled, reduce = false, ...props }) => {
   return (
      <div className={`input-inset ${classes ?? ''} ${size} ${sizeTablet} ${sizeDesk}`}>
         <div className={`${withButton ? 'd-flex' : ''} form-outline ${margin ?? 'mb-2'}`}>
            {labelText &&
               <label className="form-label">{labelText}</label>
            }

            <input
               type={type}
               min={type === 'date' ? min : undefined}
               max={type === 'date' ? max : undefined}
               className={`form-control form-control-lg ${reduce ? 'input-date' : ''}`}
               placeholder={placeholder}
               value={type === 'number' ? numbersOnly(value) : value}
               onChange={(e) => handleValue(e)}
               name={name}
               disabled={isDisabled ?? false}
               {...props}
            />

            {withButton &&
               <button className='input-inset-button form-control ms-50'><Icon icon={'Search'} /></button>
            }
         </div>
      </div>
   )
}



FormInput.propTypes = {
   type: PropTypes.string.isRequired,
   placeholder: PropTypes.string,
   // value: PropTypes.string.isRequired,
   handleValue: PropTypes.func.isRequired,
   size: PropTypes.string,
   sizeDesk: PropTypes.string,
   sizeTablet: PropTypes.string,
   name: PropTypes.string,
}

FormInput.defaultProps = {
   type: "text",
   size: "",
   sizeDesk: "",
   sizeTablet: "",
   value: "",
   name: "",
   handleValue: () => { }
}



export default FormInput;